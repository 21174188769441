import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Form, Button, Tabs, Tab } from 'react-bootstrap';

import { toast } from 'react-toastify'


import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import { formatearFecha } from '../../services/funcionesPublicas'

import moment from 'moment'


import ModalDetalleActividad from '../Operacion/ModalDetalleActividad'


import { getStartAndEndDates, obtenerInicioFinSemanaActual, formatToTwoDecimals, calcularTiempoTranscurrido } from '../../services/funcionesPublicas'


const Reportes_analiticas = () =>{
 

  const user = AuthService.getCurrentUser()


  const [ fecha, setFecha ] = useState<any>( new Date() )



  const [ servicios, setAsistencias ] = useState<any>([])
  const [ cargando_, setSeEnvio ] = useState<boolean>(false)
  const cargarAsistencias = async(fecha_inicio:any,fecha_fin:any)=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPointPost(`reporte/analiticas/${moment(fecha_inicio).format('YYYY-MM-DD')}/${moment(fecha_fin).format('YYYY-MM-DD')}`, { arreglo_estatus : arreglo_estatus} )
      setAsistencias(response.data)
      setStartDate_(fecha_inicio);
      setEndDate_(fecha_fin); 

      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      setAsistencias([])
      toast.error('Problemas al intentar cargar')
    }
  }



  const [ tolerancia_mts, setTolerancia ] = useState(40)




const acumularVisitasPorBoutique = (arreglo:any) => {
  const acumulados : any = {};

  arreglo.forEach((orden:any) => {
    const { uuid_boutique, acronimo_folio, nombre_boutique, idtipo_orden, tipo_orden } = orden;
    const boutiqueUUID = uuid_boutique;
    const tipoOrdenID = idtipo_orden;

    if (!acumulados[boutiqueUUID]) {
      acumulados[boutiqueUUID] = {
        uuid: boutiqueUUID,
        acronimo_folio: acronimo_folio,
        nombre_boutique: nombre_boutique,
        cantidad_visitas: 0,
        acumulados_tipo_orden: [],
      };
    }

    if (!acumulados[boutiqueUUID].acumulados_tipo_orden.find((t:any) => t.id === tipoOrdenID)) {
      acumulados[boutiqueUUID].acumulados_tipo_orden.push({
        id: tipoOrdenID,
        tipo_orden: tipo_orden,
        cantidad: 0,
      });
    }

    acumulados[boutiqueUUID].cantidad_visitas++;
    acumulados[boutiqueUUID].acumulados_tipo_orden.find((t:any) => t.id === tipoOrdenID).cantidad++;
  });

  return Object.values(acumulados);
}



const obtenerAcumuladosDeVisitas = (servicios:any) => {
  // Crear un objeto para acumular la información por técnico
  const acumuladosPorTecnico : any = {};

  // Iterar sobre los servicios
  servicios.forEach((servicio:any) => {
    // const tecnico = servicio.idtecnico;

    // Verificar si el técnico ya existe en los acumulados
    if (!acumuladosPorTecnico[servicio.uuid_tecnico]) {
      // Si no existe, inicializar la entrada para el técnico
      acumuladosPorTecnico[servicio.uuid_tecnico] = {
        uuid: servicio.uuid_tecnico,
        nombres: servicio.nombre_tecnico,
        paterno: servicio.paterno_tecnico,
        materno: servicio.materno_tecnico,
        sexo: servicio.sexo_tecnico,
        rol: servicio.rol_tecnico,
        celular: servicio.celular_tecnico,
        email: servicio.email_tecnico,
        curp: servicio.curp_tecnico,
        seguro_social: servicio.seguro_social_tecnico,
        rfc: servicio.rfc_tecnico,
        cantidad_servicios: 0,
        acumulados_estatus_atencion: [],
        acumulados_tipo_orden: [],
      };
    }

    // Incrementar la cantidad de servicios del técnico
    acumuladosPorTecnico[servicio.uuid_tecnico].cantidad_servicios++;

    // Obtener el estatus de atención del servicio
    const estatusAtencion = { id : servicio.idestatus_atencion, estatus : servicio.estatus };

    // Verificar si ya existe una entrada para este estatus de atención
    const estatusExistente = acumuladosPorTecnico[servicio.uuid_tecnico].acumulados_estatus_atencion.find(
      (estatus:any) => estatus.id === estatusAtencion.id
    );

    if (estatusExistente) {
      // Si existe, incrementar la cantidad
      estatusExistente.cantidad++;
    } else {
      // Si no existe, agregar una nueva entrada
      acumuladosPorTecnico[servicio.uuid_tecnico].acumulados_estatus_atencion.push({
        id: estatusAtencion.id,
        estatus: estatusAtencion.estatus,
        cantidad: 1,
      });
    }

    // Obtener el tipo de orden del servicio
    const tipoOrden = { id : servicio.idtipo_orden, tipo_orden : servicio.tipo_orden };

    // Verificar si ya existe una entrada para este tipo de orden
    const tipoOrdenExistente = acumuladosPorTecnico[servicio.uuid_tecnico].acumulados_tipo_orden.find(
      (tipo:any) => tipo.id === tipoOrden.id
    );

    if (tipoOrdenExistente) {
      // Si existe, incrementar la cantidad
      tipoOrdenExistente.cantidad++;
    } else {
      // Si no existe, agregar una nueva entrada
      acumuladosPorTecnico[servicio.uuid_tecnico].acumulados_tipo_orden.push({
        id: tipoOrden.id,
        tipo_orden: tipoOrden.tipo_orden,
        cantidad: 1,
      });
    }
  });

  // Convertir el objeto en un array
  const acumuladosArray = Object.values(acumuladosPorTecnico);

  return acumuladosArray;
}





const { inicioMes, finMes } = getStartAndEndDates(new Date());

  const [startDate, setStartDate] = useState(inicioMes);
  const [endDate, setEndDate] = useState(new Date());


  const [startDate_, setStartDate_] = useState(inicioMes);
  const [endDate_, setEndDate_] = useState(new Date());


  const onChangeRango = (dates:any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end); 
    // cargarAsistencias()
  };




  const [ tarea_seleccionada, setTareaSeleccionada ] = useState<any>({})

  const [ show_ModalDetalleActividad, setModalDetalleActividad ] = useState<boolean>(false)
  const handleShow_ModalDetalleActividad = (tarea:any) => {
    setTareaSeleccionada(tarea)
    setModalDetalleActividad(true)
  }
  const handleClose_ModalDetalleActividad = () => {
    // cargarAsistencias(startDate, endDate)
    setModalDetalleActividad(false)
  }







  React.useEffect(() => {
    cargarAsistencias(inicioMes, new Date())
  }, []);



    const acumularPorTipoDeServicio = (data:any) => {
  const acumulados : any = [];

  for (const registro of data) {
    const tipoOrden = registro.idtipo_orden;
    const estatusAtencion = registro.idestatus_atencion;

    // Busca si ya existe un acumulado para este tipo de orden
    const acumuladoExistente = acumulados.find((item:any) => item.id === tipoOrden);

    if (acumuladoExistente) {
      // Si existe, aumenta la cantidad en 1
      acumuladoExistente.cantidad++;

      // Verifica si el tipo de orden es 3, 4 o 7 y aumenta cantidad_realizados si el estatus_atencion lo permite
      if ([3, 4, 7].includes(estatusAtencion)) {
        acumuladoExistente.cantidad_realizados++;
      }
    } else {
      // Si no existe, crea un nuevo objeto de acumulado
      const nuevoAcumulado = {
        id: tipoOrden,
        tipo_orden: registro.tipo_orden,
        cantidad: 1,
        cantidad_realizados: [3, 4, 7].includes(estatusAtencion) ? 1 : 0
      };
      acumulados.push(nuevoAcumulado);
    }
  }

  return acumulados;
}


  const [ arreglo_estatus, setEstatus ] = useState<any>([true,true,true,true,true,true,true,true,true])


  const todos = () =>{
    setEstatus([true,true,true,true,true,true,true,true,true])
  }
  const ninguno = () =>{
    setEstatus([false,false,false,false,false,false,false,false,false])
  }






      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Reportes & Analíticas</h4>
                    <hr/>


                    <div className="row">
                      <div className="col-sm-2">



                            <div className="mb-3">
                              <label className="form-label">Rango de Fecha del Filtro</label>
                              <DatePicker
                              className="form-control"
                                onChange={onChangeRango}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                dateFormat="dd-MMM-yyyy"
                              />
                            </div>




                            <div>
                              <label className="form-label">Estatus de Atención</label>
                                <Form.Check inline name="group1" type="checkbox" label="PROGRAMADA" checked={arreglo_estatus[0]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}} id={`opciones_1`} />
                                <Form.Check inline name="group1" type="checkbox" label="EN PROCESO" checked={arreglo_estatus[1]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[1] = e.target.checked;  setEstatus(ne);}} id={`opciones_2`} />
                                <Form.Check inline name="group1" type="checkbox" label="ATENDIDA" checked={arreglo_estatus[2]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[2] = e.target.checked;  setEstatus(ne);}} id={`opciones_3`} />
                                <Form.Check inline name="group1" type="checkbox" label="REPORTE FINAL" checked={arreglo_estatus[3]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[3] = e.target.checked;  setEstatus(ne);}} id={`opciones_4`} />
                                <Form.Check inline name="group1" type="checkbox" label="ESPERA DE APROBACIÓN" checked={arreglo_estatus[4]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[4] = e.target.checked;  setEstatus(ne);}} id={`opciones_5`} />
                                <Form.Check inline name="group1" type="checkbox" label="SERVICIO SUSPENDIDO" checked={arreglo_estatus[5]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[5] = e.target.checked;  setEstatus(ne);}} id={`opciones_6`} />
                                <Form.Check inline name="group1" type="checkbox" label="FINALIZADO" checked={arreglo_estatus[6]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[6] = e.target.checked;  setEstatus(ne);}} id={`opciones_7`} />
                                <Form.Check inline name="group1" type="checkbox" label="LOCAL CERRADO" checked={arreglo_estatus[7]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[7] = e.target.checked;  setEstatus(ne);}} id={`opciones_9`} />
                                <Form.Check inline name="group1" type="checkbox" label="OTRO" checked={arreglo_estatus[8]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[8] = e.target.checked;  setEstatus(ne);}} id={`opciones_99`} />
                            </div>





                      <ul className="list-group list-group-flush mt-4">
                        <li className="list-group-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
                        <li className="list-group-item"><small className="pointer" onClick={(e:any)=>{cargarAsistencias(startDate,endDate)}}>Cargar Reporte</small></li>
                      </ul>









                      </div>
                      <div className="col-sm-10">
                        


                          {  (!cargando_) && (

                              <>

                                   {
                                     servicios.length > 0 && (

                                       <>


                                    <h4 className="mb-4">
                                      {
                                        moment(startDate_).format('YYYY-MM-DD') !== moment(endDate_).format('YYYY-MM-DD') && ('REPORTE ENTRE '+ moment(startDate_).format('DD [DE] MMMM [DEL] YYYY').toUpperCase() + ' Y EL ' + moment(endDate_).format('DD [DE] MMMM [DEL] YYYY').toUpperCase() )
                                      }
                                      {
                                        moment(startDate_).format('YYYY-MM-DD') === moment(endDate_).format('YYYY-MM-DD') && ('REPORTE PARA EL '+moment(startDate_).format('DD [DE] MMMM [DEL] YYYY').toUpperCase())
                                      }
                                    </h4>

                                    

                                              {
                                                // JSON.stringify(asistencias)
                                              }


                                                <Tabs
                                                  defaultActiveKey="acumulados"
                                                  id="uncontrolled-tab-example"
                                                  className="mb-3"
                                                >
                                                  <Tab eventKey="home" title="Lista de Servicios">
                                                    

                                                                                        <table className="table table-sm">
                                                                                          <thead>
                                                                                            <tr>
                                                                                              <th scope="col">Folio / Estatus</th> 
                                                                                              <th scope="col">Tipo</th>
                                                                                              <th scope="col">Técnico</th>
                                                                                              <th scope="col">Boutique</th>
                                                                                              <th scope="col">Fecha</th>
                                                                                              <th scope="col">Entrada / Salida</th>
                                                                                              <th scope="col"></th>
                                                                                            </tr>
                                                                                          </thead>
                                                                                          <tbody>{
                                                                                                  servicios.map((visita:any,indice_visita:number)=>

                                                                                                        <tr key={ indice_visita }>
                                                                                                          <td className={`v  estatus_atencion_${visita.idestatus_atencion.id}`} style={{width:170}}>
                                                                                                            <p className="nm"><b>{visita.folio}</b></p>
                                                                                                            <p className="nm"><small>{visita.idestatus_atencion.estatus}</small></p>
                                                                                                          </td> 
                                                                                                          <td className={`v tipo_orden_${visita.idtipo_orden}`} style={{width:250}}>
                                                                                                            <p className="nm"> 
                                                                                                            <small>{visita.tipo_orden}</small></p>
                                                                                                          </td> 
                                                                                                          <td className={`v estatus_atencion_${visita.idestatus_atencion}`} style={{width:250}}>
                                                                                                            <p className="nm"> 
                                                                                                            <small>{visita.estatus}</small></p>
                                                                                                          </td>
                                                                                                          <td className="v">
                                                                                                            <p className="nm"><b>{visita.nombres_tecnico}</b> <small> {visita.paterno_tecnico} {visita.materno_tecnico}</small></p>
                                                                                                            <p className="nm">  <small><i className="fa fa-phone" /> {visita.celular_tecnico} </small></p>
                                                                                                          </td> 
                                                                                                          <td className="v" style={{width:200}}>
                                                                                                            <p className="nm">{visita.nombre_boutique}</p>
                                                                                                            
                                                                                                          </td> 
                                                                                                          <td className="v" style={{width:120}}>
                                                                                                              <small>{moment(visita.fecha_programada).format('DD/MMM/YYYY').toUpperCase().replace('.','')}</small>
                                                                                                          </td> 
                                                                                                          <td className="v" style={{width:250}}>
                                                                                                            {
                                                                                                              visita.fecha_inicio_servicio && (<p className="nm"> 
                                                                                                                                                  { visita.distancia_entrada_es_cerca ? <i title={`A ${Math.round(visita.distancia_entrada)} MTS DEL LUGAR`} className="fa fa-circle" style={{color:'#00db00',textShadow:'0px 0px 6px #3de34b'}} /> : <i title={`A ${Math.round(visita.distancia_entrada)} MTS DEL LUGAR`} className="fa fa-circle" style={{color:'#f79400',textShadow:'0px 0px 6px #f57a00'}} /> }
                                                                                                                                                  {' '}
                                                                                                                                                  <small>{moment(visita.fecha_inicio_servicio).format('DD/MMM/YYYY, HH:mm [hrs]').toUpperCase()}</small>
                                                                                                                                                  </p>)
                                                                                                            }
                                                                                                            {
                                                                                                              visita.fecha_fin_servicio && (<p className="nm">
                                                                                                                                                  { visita.distancia_salida_es_cerca ? <i title={`A ${Math.round(visita.distancia_salida)} MTS DEL LUGAR`} className="fa fa-circle" style={{color:'#00db00',textShadow:'0px 0px 6px #3de34b'}} /> : <i title={`A ${Math.round(visita.distancia_salida)} MTS DEL LUGAR`} className="fa fa-circle" style={{color:'#f79400',textShadow:'0px 0px 6px #f57a00'}} /> }
                                                                                                                                                  {' '}
                                                                                                                                                  <small>{moment(visita.fecha_fin_servicio).format('DD/MMM/YYYY, HH:mm [hrs]').toUpperCase()}</small> 
                                                                                                                                                  </p>)
                                                                                                            }
                                                                                                          </td> 
                                                                                                          <td className="v" style={{width:40}}>
                                                                                                              <button onClick={(e:any)=>{handleShow_ModalDetalleActividad({ ...visita, uuid_servicio : visita.uuid })}} type="button" className="btn btn-sm btn-secondary"><i className="fa fa-calendar"/></button>
                                                                                                          </td> 
                                                                                                        </tr>


                                                                                                    )

                                                                                                }
                                                                                          </tbody>
                                                                                        </table>



                                                  </Tab>
                                                  <Tab eventKey="profile" title="Boutiques">
                                                    {
                                                      // JSON.stringify(acumularVisitasPorBoutique(servicios))
                                                    }

                                                            <table className="table">
                                                              <thead>
                                                                <tr>
                                                                  <th scope="col">Boutique</th>
                                                                  <th scope="col">Visitas</th>
                                                                  <th scope="col">Estatus de las Visitas</th>
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                {
                                                                  (acumularVisitasPorBoutique(servicios)).map((boutique:any, indice_boutique:number)=>

                                                                        <tr key={indice_boutique}>
                                                                          <td className="v" style={{width : 500}}>
                                                                            <p className="nm"><b>{boutique.nombre_boutique}</b></p>
                                                                            <p className="nm"><small>{boutique.acronimo_folio}</small></p>
                                                                          </td>
                                                                          <td className="v text-center" style={{width : 80}}>
                                                                            <p className="nm"><b>{boutique.cantidad_visitas}</b></p>
                                                                          </td>
                                                                          <td className="v">
                                                                            {
                                                                              // JSON.stringify(boutique.acumulados_tipo_orden)
                                                                            }
                                                                            <ul className="nm list-group list-group-flush">
                                                                              {
                                                                                boutique.acumulados_tipo_orden.map((tipo_orden:any, indice_tipo_orden:number)=><li className="list-group-item" key={indice_tipo_orden}>{tipo_orden.tipo_orden} <span className={`badge rounded-pill bg-light v tipo_orden_${tipo_orden.id}`}>x {tipo_orden.cantidad}</span> </li>)
                                                                              }
                                                                            </ul>
                                                                          </td>
                                                                        </tr> 

                                                                    )
                                                                }

                                                              </tbody>
                                                            </table>


                                                  </Tab> 
                                                  <Tab eventKey="tecnicos_operadores" title="Técnicos y Operadores">


                                                    {
                                                      // JSON.stringify(obtenerAcumuladosDeVisitas(servicios))
                                                    }
                                                            <table className="table">
                                                              <thead>
                                                                <tr>
                                                                  <th scope="col">Técnico</th>
                                                                  <th scope="col">Visitas</th>
                                                                  <th scope="col">Tipo de Servicio</th>
                                                                  <th scope="col">Estatus</th>
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                {
                                                                  (obtenerAcumuladosDeVisitas(servicios)).map((atendio:any, indice_atendio:number)=>

                                                                        <tr key={indice_atendio}>
                                                                          <td className="v" style={{width : 500}}>
                                                                            <p className="nm"><b>{atendio.nombres}</b></p> 
                                                                            <p className="nm"><small>{atendio.paterno} {atendio.materno} </small></p> 
                                                                          </td>
                                                                          <td className="v text-center" style={{width : 80}}>
                                                                            <p className="nm"><b>{atendio.cantidad_servicios}</b></p>
                                                                          </td>
                                                                          <td className="v">

                                                                            <ol className="nm list-group list-group-flush ">
                                                                              {
                                                                                atendio.acumulados_tipo_orden.map((tipo_orden:any, indice_tipo_orden:number)=><li className="list-group-item" key={indice_tipo_orden}>{tipo_orden.tipo_orden} <span className={`badge rounded-pill bg-light v tipo_orden_${tipo_orden.id}`}>x {tipo_orden.cantidad}</span></li>)
                                                                              }
                                                                            </ol>

                                                                          </td>
                                                                          <td className="v">
                                                                            {
                                                                              // JSON.stringify(atendio.acumulados_estatus_atencion)
                                                                            }
                                                                            <ol className="nm list-group list-group-flush">
                                                                              {
                                                                                atendio.acumulados_estatus_atencion.map((estatus_atencion:any, indice_estatus:number)=><li className="list-group-item" key={indice_estatus}>{estatus_atencion.estatus} <span className={`badge rounded-pill bg-light estatus_atencion_${estatus_atencion.id}`}>x {estatus_atencion.cantidad}</span> </li>)
                                                                              }
                                                                            </ol>
                                                                          </td>
                                                                        </tr> 

                                                                    )
                                                                }

                                                              </tbody>
                                                            </table>


                                                  </Tab> 

                                                  <Tab eventKey="acumulados" title="Acumulados">



                                                    {
                                                      // JSON.stringify(acumularPorTipoDeServicio(servicios))
                                                  }





                                                    <table className="table">
                                                      <thead>
                                                        <tr>
                                                          <th scope="col">Tipo de Servicio</th>
                                                          <th scope="col">Calendarizados</th>
                                                          <th scope="col">Realizados</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {
                                                          (acumularPorTipoDeServicio(servicios)).map((servicio:any,indice_servicio:number)=>

                                                                  <tr key={indice_servicio}>
                                                                    <td className={`v tipo_orden_${servicio.id}`}>
                                                                      <p className="nm"><b>{servicio.tipo_orden}</b></p>
                                                                    </td>
                                                                    <td className="v" style={{width:100}}>
                                                                      <p className="nm text-center"><small>{servicio.cantidad}</small></p>
                                                                    </td>
                                                                    <td className="v" style={{width:100}}>
                                                                      <p className="nm text-center"><small>{servicio.cantidad_realizados}</small></p> 
                                                                    </td>
                                                                  </tr> 

                                                            )
                                                        }

                                                      </tbody>
                                                    </table>




                                                  </Tab> 





                                                </Tabs>






                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}


                      </div>
                    </div>
                    
                </div>
              </div>

            </div>




  {
    show_ModalDetalleActividad && (
      <ModalDetalleActividad show={show_ModalDetalleActividad} handleClose={handleClose_ModalDetalleActividad} tarea={tarea_seleccionada} />
      )

  }




          </>

      );
  

}

export default Reportes_analiticas
