import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal, Card } from 'react-bootstrap';

import { toast } from 'react-toastify'

import Dialog from '../../services/Dialogs';

import { formatearNumero, formatearFechaEstandar, formatearFechaHora, sumarMontosTotales, sumarMontosTotalesAprobados } from '../../services/funcionesPublicas';
import { SERVER_ } from '../../config'

import { rol } from '../../services/rol'


import moment from 'moment'

const ModalComprobarDeposito = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, requisicion } = params

  const [ elementos_requisicion, setElementosRequisicion ] = useState(requisicion.elementos_requisicion);


  const dialog = new Dialog();


  const [envio_server, setSeEnvio] = useState(false);

  const enviarFormulario = async (uuid_elemento:string,monto:number,estatus:any, event:any) => {

    let TEMPLATE = ``

    if(estatus==='COMPROBADO') TEMPLATE = `<label>Monto Comprobado <input type="number" name="monto_comprobado" value="${monto}"></label>`
    if(estatus==='VOLVER_A_COMPROBAR') TEMPLATE = `

<div class="mb-3">
  <label for="exampleFormControlTextarea1" class="form-label">Explicación del motivo que tenga que volver a comprobarse:</label>
  <textarea class="form-control" name="comentario_volver_a_comprobar" id="exampleFormControlTextarea1" style="text-transform: uppercase" rows="5"></textarea>
</div>

    `

    await dialog.open({
      accept: 'Si',
      cancel: 'Espera',
      dialogClass: '',
      message: '¿Estás seguro?',
      target: event.target,
      // template: ''
    template: TEMPLATE

    });
    let dialogo : any = await dialog.waitForUser();
    if (dialogo) {
      try {
        setSeEnvio(true);

        // console.log(dialogo)
        let DATA_SEND : any = {
          estatus : estatus
        }

        if(estatus==='COMPROBADO') DATA_SEND.monto_comprobado = parseFloat(dialogo.monto_comprobado)
        if(estatus==='VOLVER_A_COMPROBAR') DATA_SEND.comentario_volver_a_comprobar = dialogo.comentario_volver_a_comprobar

        let res_ = await RequestService.update(`requisicion/elemento/comprobar_evidencia_deposito/${uuid_elemento}`, DATA_SEND );

        // let res_ = null

        if (res_) {
          toast.success('El Estado del elemento fue actualizado');
          setTimeout(() => {
            cargarElementos_requisicion();
          }, 0);
        } else {
          toast.error('Problemas...');
        }
      } catch (e:any) {
        toast.error(e.response.data);
      }
    }
  };


  const cargarElementos_requisicion = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`comprobaciones/elementos_requisicion/${requisicion.uuid_requisicion}`)
      console.log(response.data)
      setElementosRequisicion(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }



const verificarIdEstatusElementos = (data:any) => {
  // Definimos los valores permitidos
  const valoresPermitidos = [5, 6, 8, 99];

  // Verificamos si todos los elementos cumplen con la condición
  return data.every((item:any) => valoresPermitidos.includes(item.idestatus_elemento.id));
}



const terminarComprobacion = async (event:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `comprobaciones/terminar/${requisicion.uuid_requisicion}` , { })

        if(res_){
          toast.success('La Requisición fué finalizada correctamente')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){

          if (e.response?.status) {
            switch (e.response.status) {
              default:
                toast.error(e.response?.data)
            }
          }
          else{
            toast.error("Problemas con la busqueda")
          }
          setSeEnvio(false)

        }
        
      }
     
}







  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Comprobación de Evidencia</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(elementos_requisicion)
          }

            <h6 className="">Descripción de la Requisición</h6>
            <small>{requisicion.descripcion}</small>

            <div className="row mt-2">
              <div className="col-4">
                  <h6>Folio</h6>
                  <small>{requisicion.folio}</small>
              </div>
              <div className="col-4">
                  <h6 className="mt-3-">Fecha Límite</h6>
                  <small>{moment(requisicion?.fecha_limite).utc().format('DD/MMM/YYYY').replace('.','').toUpperCase()}</small>
              </div>
              <div className="col-4">
                  <h6 className="mt-3-">Fecha de Creación</h6>
                  <small>{formatearFechaHora(requisicion?.fecha_creacion)} hrs</small>
              </div>
            </div>

{
  // JSON.stringify(elementos_requisicion)
}

            <div className={`row ${requisicion.compra_proveedor===0?'mb-3':''}`}>
              <div className="col-6">
                  <h6 className="mt-3">Requisitó</h6>
                  <p className="nm"><small><b>{requisicion.idusuario_crea?.nombres}</b> {requisicion.idusuario_crea?.paterno} {requisicion.idusuario_crea?.materno}</small></p>
                  <p className="nm"><small style={{fontSize:10}}>{rol(requisicion.idusuario_crea?.rol)}</small></p>
              </div>
              <div className="col-3">
                  <h6 className="mt-3">Monto Total</h6>
                  <small>$ {formatearNumero(sumarMontosTotales(requisicion?.requisiciones_listado??[]))}</small>
              </div>
              <div className="col-3">
                  <h6 className="mt-3">Monto Aprobado</h6>
                  <small>$ {formatearNumero(sumarMontosTotalesAprobados(requisicion?.requisiciones_listado??[]))}</small>
              </div>
            </div>


{
  // requisicion.compra_proveedor
}

            <div className="row">
              <div className="col-6">
              {
                requisicion.compra_proveedor === 1 && (
                    <div>
                      <h6 className="">Proveedor</h6>
                      <small>{requisicion?.idproveedor?.proveedor} ({requisicion?.idproveedor?.razon_social})</small>
                    </div>
                  )
              }
              </div>
            </div>



          <ul className="list-inline">
            {/*<li className="list-inline-item">This is a list item.</li>*/}
          </ul>

{
  // JSON.stringify(elementos_requisicion)
}


          <div>
            {elementos_requisicion.map((elemento_requisicion:any, elemento_requisicion_indice:any) => (
              <React.Fragment key={elemento_requisicion_indice}>


{
  // JSON.stringify(elemento_requisicion.idestatus_elemento)
}

                {[99].indexOf(elemento_requisicion.idestatus_elemento.id) > -1 && (
                  <Card  className="mb-0">
                    <Card.Header>
                      <h6 className="nm">
                          <i className="fa fa-check" />{' '}
                          {elemento_requisicion.concepto}
                      </h6>
                    </Card.Header>
                    <Card.Body>

 
                          <div className="row mt-2">
                            <div className="col-2">
                                <small>Monto Solicitado</small>
                                <h6 className="nm">$ {formatearNumero(elemento_requisicion?.monto_total)}</h6>
                            </div>
                            <div className="col-2">
                                <small>Monto Depositado</small>
                                <h6 className="nm">$ {formatearNumero(elemento_requisicion?.idegreso?.monto)}</h6>
                            </div>
                            <div className="col-2">
                                <small>Tipo de requisición</small>
                                <h6 className="nm">{elemento_requisicion?.tipo.toUpperCase()}</h6>
                            </div>
                            <div className="col-6">
                                <small>Concepto del Depósito</small>
                                <h6 className="nm">{elemento_requisicion?.idegreso?.descripcion}</h6>
                            </div>
                          </div>


                          <small>Estatus</small>
                          <h6 className="nm">{elemento_requisicion.idestatus_elemento.estatus}</h6>

                    </Card.Body>
                  </Card>
                  )}






                {[3,4,5,6,7,8,9,10].indexOf(elemento_requisicion.idestatus_elemento.id) > -1 && (
                  <Card className="mb-0">
                    <Card.Header>
                      <h6 className="nm">
                        {elemento_requisicion.documento_evidencia === null && (
                          <i className="fa fa-warning" />
                        )}
                        {' '}{elemento_requisicion.concepto}
                      </h6>
                    </Card.Header>
                    <Card.Body>
                    {
                      // JSON.stringify(elemento_requisicion?.idestatus_elemento?.id)
                    }
                      {
                        elemento_requisicion.documento_evidencia === null && (
                            <>
                              <p>⚠️ Aún no suben archivo de comprobanción </p>


                                  <button type="button" onClick={(e:any)=>{enviarFormulario(elemento_requisicion.uuid,0,"NO_COMPROBADO",e)}} className="btn btn-warning">NO COMPROBADO</button>
                                  {' '}
                                  <button type="button" onClick={(e:any)=>{enviarFormulario(elemento_requisicion.uuid,0,"NO_NECESITA_COMPROBARSE",e)}} className="btn btn-primary">NO NECESITA COMPROBARSE</button>

                                  <p className="mt-4"><small>Estatus</small></p>
                                  <h6 className="">{elemento_requisicion?.idestatus_elemento.estatus} <small>( TODAVÍA PUEDES CAMBIAR ESTATUS )</small></h6>

                            </>
                          )
                      }


                      {
                        elemento_requisicion.documento_evidencia !== null && (
                        <>
                          <p className="">
                            Comprobación {' '}
                            <b>{elemento_requisicion.con_factura ? 'CON UNA FACTURA 🧾' : 'SIN FACTURA'}</b>
                          </p>




                          <small>Concepto de la requisición</small>
                          <h6 className="nm">{elemento_requisicion?.concepto}</h6>


                          <div className="row mt-2">
                            <div className="col-2">
                                <small>Monto Solicitado</small>
                                <h6 className="nm">$ {formatearNumero(elemento_requisicion?.monto_total)}</h6>
                            </div>
                            <div className="col-2">
                                <small>Monto Depositado</small>
                                <h6 className="nm">$ {formatearNumero(elemento_requisicion?.idegreso?.monto)}</h6>
                            </div>
                            <div className="col-2">
                                <small>Tipo de requisición</small>
                                <h6 className="nm">{elemento_requisicion?.tipo.toUpperCase()}</h6>
                            </div>
                            <div className="col-6">
                                <small>Concepto del Depósito</small>
                                <h6 className="nm">{elemento_requisicion?.idegreso?.descripcion}</h6>
                            </div>
                          </div>


{
  // JSON.stringify(elemento_requisicion.idestatus_elemento)
}


                          <div className="row mt-2 mb-2">
                            <div className="col-4">
                                <small>Ćomprobación de Depósito</small>
                                <p className="nm"><button onClick={()=>{ window.open(`${SERVER_}contabilidad/archivo/${elemento_requisicion?.idegreso?.uuid}`) }} className="btn btn-sm btn-link nm">Abrir Archivo</button></p>
                            </div>
                            <div className="col-4">
                                <small>Ćomprobación de Evidencia del Gasto</small>
                                <p className=""><button onClick={()=>{ window.open(`${SERVER_}comprobaciones/evidencia/archivo/${elemento_requisicion?.uuid}`) }} className="btn btn-sm btn-link nm">Abrir Archivo</button></p>
                            </div>
                            <div className="col-4">
                                  <small>Fecha del Movimiento</small>
                                  <h6 className="nm">{formatearFechaEstandar(elemento_requisicion?.idegreso?.fecha_movimiento)}</h6>
                            </div>
                          </div>

                          {
                            [6].indexOf(elemento_requisicion.idestatus_elemento.id) > -1 && (
                                <>
                                  <small>Monto Comprobado</small>
                                  <h6 className="">$ {formatearNumero(elemento_requisicion?.monto_comprobado)}</h6>

                                  <p>
                                    <i className="fa fa-check-square" /> Comprobado
                                  </p>
                                </>
                              )
                          }


                          {
                            !([4].indexOf(elemento_requisicion.idestatus_elemento.id) > -1) && (
                                <>
                                  <small>Estatus</small>
                                  <h6 className="">{elemento_requisicion?.idestatus_elemento.estatus}</h6>

                                  {/*{JSON.stringify(elemento_requisicion.idestatus_elemento)}*/}
                                </>
                              )
                          }

                          {
                            [3,4].indexOf(elemento_requisicion.idestatus_elemento.id) > -1 && (
                                <>
                                  <button type="button" onClick={(e:any)=>{enviarFormulario(elemento_requisicion.uuid,elemento_requisicion?.idegreso?.monto,"COMPROBADO",e)}} className="btn btn-success">COMPROBADO</button>
                                  {' '}
                                  <button type="button" onClick={(e:any)=>{enviarFormulario(elemento_requisicion.uuid,0,"NO_COMPROBADO",e)}} className="btn btn-warning">NO COMPROBADO</button>
                                  {' '}
                                  <button type="button" onClick={(e:any)=>{enviarFormulario(elemento_requisicion.uuid,0,"DEVOLUCION",e)}} className="btn btn-danger">PARA DEVOLUCIÓN</button>
                                  {' '}
                                  <button type="button" onClick={(e:any)=>{enviarFormulario(elemento_requisicion.uuid,0,"VOLVER_A_COMPROBAR",e)}} className="btn btn-primary">VOLVER A COMPROBAR</button>
                                  {' '}
                                  <button type="button" onClick={(e:any)=>{enviarFormulario(elemento_requisicion.uuid,0,"NO_NECESITA_COMPROBARSE",e)}} className="btn btn-primary">NO NECESITA COMPROBARSE</button>
                                </>
                              )
                          }

                          



                        </>
                      )
                      }
                    </Card.Body>
                  </Card>
                )}
              </React.Fragment>
            ))}
          </div>
        </Modal.Body>




        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar Ventana
          </Button>
          <Button variant="warning" disabled={!verificarIdEstatusElementos(elementos_requisicion)} onClick={terminarComprobacion} type="button">Terminar la Comprobación <i className="fa fa-save"/></Button>
        </Modal.Footer>










      </Modal>
    </>
  );
}


export default ModalComprobarDeposito;