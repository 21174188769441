import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import Dialog from '../../services/Dialogs'


const ModalListaSupervision = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, equipo } = params

const dialog = new Dialog();


  
    const [ lista_supervision, setAreasAtencion ] = useState<any>(equipo.lista_supervision?equipo.lista_supervision:[] )


    const [ envio_server, setSeEnvio ] = useState<boolean>(false)
    const enviarFormulario = async (event:any) =>{

          await dialog.open({
            accept: 'Si',
            cancel: 'Espera',
            dialogClass: '',
            message: '¿Estás seguro de la información?',
            target: event.target,
            template: ``
          })
          let dialogo : any = await dialog.waitForUser()
          if(dialogo){
            try{

            setSeEnvio(true)
            let res_ = await RequestService.update( `equipo_critico/lista_supervision/${equipo.uuid}` , { lista_supervision: lista_supervision })
            if(res_){
                toast.success('Las Áreas de Atención fueron actualizadas')
            }
            else{
              toast.error('Problemas...')
            } 
            }
            catch(e:any){
              setSeEnvio(true)
              toast.error(e.response.data)
              // console.log(e.response.data)
            } 
            
          }
         
    }



const eliminarArea = async (event:any,indice:number) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        
          let n = [...lista_supervision]

          n.splice(indice,1)

          setAreasAtencion(n)
        
      }
     
}




    

const agregarListaCaminata = async (event:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        let n = [...lista_supervision]

        // n.splice(1,indice)
        n.push("")

          setAreasAtencion(n)
        
      }
     
}


const eliminarRegistroLista = async (event:any,indice:number) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        let n = [...lista_supervision]

        n.splice(indice,1)

          setAreasAtencion(n)
        
      }
     
}


    


  return (
    <>
      <Modal show={show}  onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Tareas de Supervisión</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          {
            // JSON.stringify(equipo)
          }
          {
            !lista_supervision && (
                <React.Fragment>
                    <div className="alert alert-danger" role="alert">
                      Es importante configurar las áreas de atención.
                    </div>
                </React.Fragment> 
              )
          }




          {
            lista_supervision && (
                <React.Fragment>
                  {
                    // JSON.stringify(lista_supervision)
                  }

                  {
                    // lista_supervision.map((area:any,indice:number)=>

                    //           <div key={indice}  className="card">
                    //             <div className="card-header">
                    //               {
                    //                 // JSON.stringify(area.area)
                    //               }



                    //             </div>
                    //             <div className="card-body">
                    //               <p><b>Lista de Inspección para Supervisión </b></p>
                    //               {
                    //                 // JSON.stringify(area.lista_caminata_supervision.length)
                    //               }

                    //                 {
                    //                   area.lista_caminata_supervision.length === 0 && (
                    //                       <React.Fragment>
                    //                           <div className="alert alert-danger" role="alert">
                    //                             Es importante agregar la lista de inspección para Supervisión.
                    //                           </div>
                    //                       </React.Fragment> 
                    //                     )
                    //                 }




                    //             </div>
                    //           </div>

                    //   )
                  }
                                    {
                                      lista_supervision.length > 0 && (
                                          <React.Fragment>
                                              {
                                                lista_supervision.map((registro:any,indice:number)=>
                                                        <div key={indice} className="hstack gap-3 mb-2">
                                                          <input className="form-control  form-control-sm me-auto" type="text" value={registro} onChange={(e:any)=>{ let a = [...lista_supervision]; a[indice] = e.target.value.toUpperCase(); setAreasAtencion(a) }} />
                                                          <div className="vr"></div>
                                                          <button type="button" onClick={(e:any)=>{eliminarRegistroLista(e,indice)}} className="btn btn-sm btn-outline-danger"><i className="fa fa-trash"/></button>
                                                        </div>
                                                  )
                                              }

                                          </React.Fragment> 
                                        )
                                    }
                                    <button type="button" onClick={(e:any)=>{agregarListaCaminata(e)}} className="btn btn-link">Agregar tarea</button>



                </React.Fragment>
              )
          }

 





        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar Ventana
          </Button>
          <Button variant="success" type="button" onClick={enviarFormulario}>Guardar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalListaSupervision;