import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'

import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'



import ModalEditarAnexoTrabajador from './ModalEditarAnexoTrabajador'
import ModalAgregarAnexoTrabajador from './ModalAgregarAnexoTrabajador'


const AnexosTrabajadores = () =>{

  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`catalogo_anexos_trabajador/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e:any){

      if (e.response?.status) {
        switch (e.response.status) {
          case 404:
            toast.error("Debes escribir algo")
            break;
          default:
            toast.error(e.response?.data)
        }
      }
      else{
        toast.error("Problemas con la busqueda")
      }
      setCargando(false)

    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }


      React.useEffect(() => {
        busqueda()
      }, []);




  const [ show_ModalEditarAnexoTrabajador, setModalEditarAnexoTrabajador ] = useState<boolean>(false)
  const [ anexo_seleccionado, setAnexoSeleccionado ] = useState<any>(null)
  const handleShow_ModalEditarAnexoTrabajador = (anexo:any) => {
    setAnexoSeleccionado(anexo)
    setModalEditarAnexoTrabajador(true)
  }
  const handleClose_ModalEditarAnexoTrabajador = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalEditarAnexoTrabajador(false)
  }



  const [ show_ModalAgregarAnexoTrabajador, setModalAgregarAnexoTrabajador ] = useState<boolean>(false)
  const handleShow_ModalAgregarAnexoTrabajador = () => {
    setModalAgregarAnexoTrabajador(true)
  }
  const handleClose_ModalAgregarAnexoTrabajador = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalAgregarAnexoTrabajador(false)
  }





      return (
          <>
          <h6>Cátalogo de Anexos de Trabajadores</h6>

        
            <div className="mb-2">
                <label className="form-label">Búsqueda</label>
                <div className="hstack gap-3">
                  <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                  <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                  <div className="vr"></div>
                  <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                </div>
            </div>
            

          <ul className="list-inline">
            <li className="list-inline-item"><small className="pointer" onClick={handleShow_ModalAgregarAnexoTrabajador}>Agregar Anexo</small></li>
          </ul>



                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col">Documento</th>
                                              <th scope="col" className="text-center">Ve Cliente</th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (documento:any,indice:number) =>
                                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}}>
                                                    <td className="v text-left">
                                                          <p className="nm">{documento.documento}</p>
                                                    </td>
                                                    <td className="v text-center" style={{"width":"180px"}}>
                                                      {
                                                        // JSON.stringify(documento)
                                                      }
                                                      { documento.debe_visualizar_cliente && <p className="nm">SI</p> }
                                                    </td>
                                                    <td className="v text-left" style={{"width":"80px"}}>
                                                      <button onClick={(e:any)=>{handleShow_ModalEditarAnexoTrabajador(documento)}} className="btn btn-sm btn-warning"><i className="fa fa-edit"/></button>
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}




  {
    show_ModalEditarAnexoTrabajador && (
      <ModalEditarAnexoTrabajador show={show_ModalEditarAnexoTrabajador} handleClose={handleClose_ModalEditarAnexoTrabajador} anexo={anexo_seleccionado} />
      )

  }



  {
    show_ModalAgregarAnexoTrabajador && (
      <ModalAgregarAnexoTrabajador show={show_ModalAgregarAnexoTrabajador} handleClose={handleClose_ModalAgregarAnexoTrabajador}  />
      )

  }










          </>

      );
  

}

export default AnexosTrabajadores
