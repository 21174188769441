import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import Dialog from '../../services/Dialogs'

import moment from 'moment'



import AgregarElementoRequisicion from './AgregarElementoRequisicion'
import EditarElementoRequisicion from './EditarElementoRequisicion'



import { sumarMontosTotales, tipoDeElementoRequisicion, formatearNumero, obtenerUnidadMedida } from '../../services/funcionesPublicas'

import { SERVER_ } from '../../config'

const ModalNuevaRequisicion = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, proveedor } = params


  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape(
  proveedor 
  ?
  {
          para_compras : yup.boolean(),
          descripcion : yup.string().required(mensajes_validacion_generico),
          fecha_limite : yup.string().required(mensajes_validacion_generico),
          idproveedor : yup.string().required(mensajes_validacion_generico),
          idusuario_solicita : yup.string().nullable(),
  }
  :
  {
          para_compras : yup.boolean(),
          descripcion : yup.string().required(mensajes_validacion_generico),
          fecha_limite : yup.string().required(mensajes_validacion_generico),
          idusuario_solicita : yup.string().nullable(),
  }
  

  );




  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

            if(requisiciones_listado.length == 0){
              toast.error('No puedes agregar una requisición sin ningún elemento')
              return 
            }

          setSeEnvio(true)
          let res_ = await RequestService.create( proveedor ? `requisicones/nueva_compra_proveedor/proveedor` : `requisicones/nueva_requisicion/requisicion` , { ...registro, fecha_limite : moment(registro.fecha_limite).format('YYYY-MM-DD'), requisiciones_listado : requisiciones_listado })

          if(res_){
            toast.success('La Requisición se creó correctamente')
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }



  const [ requisiciones_listado, setRequisicionListado ] = useState<any>([])
  const [ show_AgregarElementoRequisicion, setAgregarElementoRequisicion ] = useState<boolean>(false)
  const [ tipo_elemento_requisicion, setTipoElementoRequisicion ] = useState('')
  const handleShow_AgregarElementoRequisicion = (tipo_elemento_requisicion:string) => {
    setTipoElementoRequisicion(tipo_elemento_requisicion)
    setAgregarElementoRequisicion(true)
  }
  const handleClose_AgregarElementoRequisicion = () => {
    setAgregarElementoRequisicion(false)
  }


const eliminarRegistroPorIndice = (indice:number) => {
  if (indice >= 0 && indice < requisiciones_listado.length) {
    setRequisicionListado([...requisiciones_listado.slice(0, indice), ...requisiciones_listado.slice(indice + 1)]);

  } 
};



  const [ proveedores, setProveedores ] = useState<any>([])
  const cargarProveedores = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/proveedores`)
      setProveedores(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }




  React.useEffect(() => {
    cargarProveedores()
    cargarTipo_unidad_medida()
    cargarLista_tecnicos()
  }, []);




  const [ tipo_unidad_medida, setTipo_unidad_medida ] = useState<any>([])
  const cargarTipo_unidad_medida = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/tipo_unidad_medida`)
      setTipo_unidad_medida(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }



  const [ show_EditarElementoRequisicion, setEditarElementoRequisicion ] = useState<boolean>(false)
  const [ indice_elemento_requisicion_seleccionado, setIndiceElementoRequisicionSeleccionado ] = useState<any>(null)
  const handleShow_EditarElementoRequisicion = (elemento:any,indice:number) => {
    setIndiceElementoRequisicionSeleccionado(indice)
    setEditarElementoRequisicion(true)
  }
  const handleClose_EditarElementoRequisicion = () => {
    setEditarElementoRequisicion(false)
  }



  const [ lista_quien_atiende, setLista_tecnicos ] = useState<any>({
      tecnicos : [],
      conservacion : [],
      limpieza : [],
      obra : [],
  })

  const cargarLista_tecnicos = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`zonas/lista_tecnicos`)
      setLista_tecnicos(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }

  const copiarDesdePortaPapeles  = async (e:any) => {
    try {
      // Utilizar la API del portapapeles para leer el texto
      const pastedData : any = await navigator.clipboard.readText();

      // Separar las filas por saltos de línea
      const rows = pastedData.split('\n');

      // Mapear cada fila a un objeto con la estructura deseada
      const formattedData = rows.map((row:any) => {
        const columns = row.split('\t'); // Separar las columnas por tabulaciones
        console.log((columns))
        return {
          tipo: columns[0],
          con_factura: columns[1] === 'SI', // Convierte "SI" en true y "NO" en false
          concepto: columns[2],
          monto_total: columns[3], 
          idunidad_medida: (parseInt(columns[4])),
          cantidad: columns[5],
          meta_data: {
            nombre_razon_social: columns[6],
            banco: columns[7],
            cuenta_referencia: columns[8],
            detalles_deposito: columns[9]
          },
            nombre_razon_social: columns[6],
            banco: columns[7],
            cuenta_referencia: columns[8],
            detalles_deposito: columns[9]
        };
      });

      // console.log(formattedData)
      setRequisicionListado([ ...requisiciones_listado, ...formattedData ])


    } catch (error) {
      console.error('Error al copiar desde el portapapeles: ', error);
    }
  };

  const copiarDesdePortaPapelesServicio  = async (e:any) => {
    try {
      // Utilizar la API del portapapeles para leer el texto
      const pastedData : any = await navigator.clipboard.readText();

      // Separar las filas por saltos de línea
      const rows = pastedData.split('\n');

      // Mapear cada fila a un objeto con la estructura deseada
      const formattedData = rows.map((row:any) => {
        const columns = row.split('\t'); // Separar las columnas por tabulaciones
        // console.log((columns))
        return {
          tipo: columns[0],
          con_factura: columns[1] === 'SI', // Convierte "SI" en true y "NO" en false
          concepto: columns[2],
          monto_total: columns[3], 
          // idunidad_medida: columns[4],
          // cantidad: columns[5],
          meta_data: {
            nombre_razon_social: columns[4],
            banco: columns[5],
            cuenta_referencia: columns[6],
            detalles_deposito: columns[7]
          },
            nombre_razon_social: columns[4],
            banco: columns[5],
            cuenta_referencia: columns[6],
            detalles_deposito: columns[7]
        };
      });

      // console.log(formattedData)
      setRequisicionListado([ ...requisiciones_listado, ...formattedData ])


    } catch (error) {
      console.error('Error al copiar desde el portapapeles: ', error);
    }
  };

  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Nueva Requisición</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }


    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={
                      proveedor ? 
                      {
                        para_compras : false,
                        descripcion : "",
                        fecha_limite : new Date(),
                        idproveedor : "",
                        idusuario_solicita : "",
                      }
                      : 
                      {
                        para_compras : false,
                        descripcion : "",
                        idusuario_solicita : "",
                        fecha_limite : new Date(),
                      }
    }
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>


            <Form.Group className="mb-3" controlId="descripcion">
              <Form.Label>Descripción de la Requisición</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="descripcion"
                value={values.descripcion}
                onBlur={(e)=>{ setFieldValue('descripcion', e.target.value.toUpperCase() ) }}
                onChange={(e)=>{ setFieldValue('descripcion', e.target.value ) }}
                isInvalid={!!errors.descripcion}
                style={{textTransform:'uppercase'}}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.descripcion}</>} </Form.Control.Feedback>
            </Form.Group>


            <div className="row">
              <div className="col-sm-3">
                  <div className="mb-3">
                    <Form.Label>Fecha Límite</Form.Label>
                    <DatePicker selected={values.fecha_limite} className="form-control"  dateFormat="yyyy-MM-dd" minDate={new Date()} onChange={(date) => setFieldValue('fecha_limite', date ) } />
                  </div>
              </div>

{
  // JSON.stringify(values)
}



              {
                ["ROLE_RESPONSABLE_TECNICOS","AUXILIAR_OBRA"].indexOf(user.rol) > -1 && (
                    <div className="col-sm-5"> 


                         <Form.Group className="mb-3" controlId="idusuario_solicita">
                          <Form.Label>Selecciona a quien solicita la Requisición</Form.Label>
                          <Form.Select required name="idusuario_solicita" value={values.idusuario_solicita} onChange={(e)=>{ setFieldValue('idusuario_solicita', e.target.value ) }} isInvalid={!!errors.idusuario_solicita}>
                            <option value="">LA REQUISICIÓN ES MIA</option>

                                  <>

                                    {
                                      ["ROLE_RESPONSABLE_TECNICOS"].indexOf(user.rol) > -1 && (
                                        <>
                                            {
                                              lista_quien_atiende.conservacion.map((tecnico:any,tecnico_indice:number)=>
                                                  <option key={tecnico_indice} value={tecnico.uuid}>{tecnico.nombres} {tecnico.paterno} {tecnico.materno} </option>
                                                )
                                            }

                                            {
                                              lista_quien_atiende.tecnicos.map((tecnico:any,tecnico_indice:number)=>
                                                  <option key={tecnico_indice} value={tecnico.uuid}>{tecnico.nombres} {tecnico.paterno} {tecnico.materno} </option>
                                                )
                                            }
                                        </>
                                      )
                                    }



                                    {
                                      ["AUXILIAR_OBRA"].indexOf(user.rol) > -1 && (
                                        <>
                                          {
                                            lista_quien_atiende.obra.map((tecnico:any,tecnico_indice:number)=>
                                                <option key={tecnico_indice} value={tecnico.uuid}>{tecnico.nombres} {tecnico.paterno} {tecnico.materno} </option>
                                              )
                                          }
                                        </>
                                      )
                                    }


                                  </>


                          </Form.Select>
                          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid"> {<>{errors.idusuario_solicita}</>} </Form.Control.Feedback>
                        </Form.Group>


                    </div>
                  )
              }

              <div className="col-sm-4">

        {
          proveedor && (

                <Form.Group className="mb-4" controlId="idproveedor">
                  <Form.Label>Selecciona el Proveedor</Form.Label>
                  <Form.Select required name="idproveedor" value={values.idproveedor} onChange={(e)=>{ setFieldValue('idproveedor', e.target.value ) }} isInvalid={!!errors.idproveedor}>
                    <option value="">SELECCIONA EL PROVEEDOR</option>
                    {
                      proveedores.map( (proveedor:any,proveedor_indice:number) =>
                        <React.Fragment key={proveedor_indice}>
                                  <option value={proveedor.uuid}> {proveedor.proveedor}</option>
                        </React.Fragment>
                      )
                    }

                  </Form.Select>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">{<>{errors.idproveedor}</>}</Form.Control.Feedback>
                </Form.Group>

            )
        }

              </div>



            </div>


                  <div className="form-check mb-4">
                    <input className="form-check-input" type="checkbox" onChange={(e:any)=>{ setFieldValue('para_compras', e.target.checked ) }} checked={values.para_compras} id="para_compras" />
                    <label className="form-check-label" htmlFor="para_compras">
                      Esta requisición debe pasar por compras
                    </label>
                  </div>

            {
              // JSON.stringify(proveedor)
            }


            { !proveedor && ( <button type="button" onClick={(e:any)=>{handleShow_AgregarElementoRequisicion('pago')}} className="btn btn-sm btn-link">Agregar Servicio</button> ) }
            { !proveedor && ( <button type="button" onClick={(e:any)=>{copiarDesdePortaPapelesServicio(e)}} className="btn btn-sm btn-link"><i className="fa fa-copy"/></button> ) }
            { true && ( <button type="button" onClick={(e:any)=>{handleShow_AgregarElementoRequisicion('compra')}} className="btn btn-sm btn-link">Agregar Compra</button> ) }
            { true && ( <button type="button" onClick={(e:any)=>{copiarDesdePortaPapeles(e)}} className="btn btn-sm btn-link"><i className="fa fa-copy"/></button> ) }
            { !proveedor && ( <button type="button" onClick={(e:any)=>{handleShow_AgregarElementoRequisicion('viaticos')}} className="btn btn-sm btn-link">Agregar Víaticos</button> ) }
            { !proveedor && ( <button type="button" onClick={(e:any)=>{copiarDesdePortaPapelesServicio(e)}} className="btn btn-sm btn-link"><i className="fa fa-copy"/></button> ) }
            { !proveedor && ( <button type="button" onClick={(e:any)=>{handleShow_AgregarElementoRequisicion('reembolso')}} className="btn btn-sm btn-link">Agregar Reembolso</button> ) }
            { !proveedor && ( <button type="button" onClick={(e:any)=>{copiarDesdePortaPapelesServicio(e)}} className="btn btn-sm btn-link"><i className="fa fa-copy"/></button> ) }





            {
              requisiciones_listado.length === 0 && (
                  <div className="alert alert-danger mt-3">
                    <i className="fa fa-warning" /> Debes agregar por lo menos un elemento a la solicitud
                  </div>
                )
            }

            {
              requisiciones_listado.length > 0 && (
                  <div className="mt-3">

                      {
                        // JSON.stringify(requisiciones_listado)
                      }

                        <table className="table">
                          <thead>
                            <tr>
                              {
                                !proveedor && (<th scope="col">Factura</th>)
                              }
                              
                              <th scope="col">Tipo</th>
                              <th scope="col">Concepto</th>
                              <th scope="col">Monto</th>
                              <th scope="col"></th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              requisiciones_listado.map(( elemento:any, elemento_indice:number )=>
                                          <tr key={elemento_indice}>
                                              { 
                                                  !proveedor && (
                                                      <td className="v" style={{"width":"20px",textAlign:'center'}}>
                                                        { elemento.con_factura && <i className="fa fa-warning" />}
                                                      </td>
                                                  ) 
                                              }

                                            <td className="v" style={{"width":"160px"}}>
                                              <b>{tipoDeElementoRequisicion(elemento.tipo)}</b>
                                            </td>
                                            <td className="v">
                                            {
                                              // JSON.stringify(elemento)
                                            }
                                              <small>{elemento.concepto}</small>
                                            </td>

                                            { 
                                              // ["pago","viaticos","reembolso"].indexOf(elemento.tipo) > -1 && (
                                              //   <>
                                              //     <td className="v" style={{"width":"80px"}}><small>N/A</small></td>
                                              //     <td className="v" style={{"width":"180px"}}><small>N/A</small></td>
                                              //   </>
                                              // ) 
                                            }

                                            { 
                                              // ["compra"].indexOf(elemento.tipo) > -1 && (
                                              //   <>
                                              //     <td className="v" style={{"width":"80px"}}>{elemento.cantidad}</td>
                                              //     <td className="v" style={{"width":"180px"}}>
                                              //       {
                                              //         obtenerUnidadMedida(tipo_unidad_medida,parseInt(elemento.idunidad_medida))
                                              //       }
                                              //       {
                                              //         // JSON.stringify(elemento.idunidad_medida)
                                              //       }
                                              //     </td>
                                              //   </>
                                              // ) 
                                            }
                                            
                                            <td className="v" style={{"width":"100px"}}>$ {formatearNumero((elemento.monto_total*1))}</td>
                                            
                                            <td style={{"width":"30px"}}><button type="button" onClick={(e:any)=>{handleShow_EditarElementoRequisicion(elemento,elemento_indice)}} className="btn btn-sm btn-warning"><i className="fa fa-edit"/></button></td>
                                            <td style={{"width":"30px"}}><button type="button" onClick={(e:any)=>{eliminarRegistroPorIndice(elemento_indice)}} className="btn btn-sm btn-danger"><i className="fa fa-trash"/></button></td>
                                          </tr>
                                )
                            }

                            <tr>

                              {
                                !proveedor && (<td></td>)
                              }
                              <td></td>
                              <td className="v text-right"><b>Total</b></td>
                              <td>
                                $ {formatearNumero(sumarMontosTotales(requisiciones_listado))}
                              </td>


                              <td></td>
                              <td></td>


                            </tr>

                          </tbody>
                        </table>

                  </div>
                )
            }


            <button type="button" onClick={(e:any)=>{window.open(`${SERVER_}download-layout`)}} className="btn btn-sm btn-link"><i className="fa fa-file-excel-o" /> Descargar layout de migración de elementos por copia en Excel</button>




        </Form>
      )}
    </Formik>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>




      {
        show_AgregarElementoRequisicion && (
          <AgregarElementoRequisicion 
                                show={show_AgregarElementoRequisicion} 
                                handleClose={handleClose_AgregarElementoRequisicion} 
                                tipo_elemento_requisicion={tipo_elemento_requisicion} 
                                requisiciones_listado={requisiciones_listado} 
                                setRequisicionListado={setRequisicionListado} 
                                proveedor={proveedor} 

              />

          )

      }



  {
    show_EditarElementoRequisicion && (
      <EditarElementoRequisicion show={show_EditarElementoRequisicion} handleClose={handleClose_EditarElementoRequisicion} 
                                requisiciones_listado={requisiciones_listado} 
                                setRequisicionListado={setRequisicionListado} 
                                indice_elemento_requisicion_seleccionado={indice_elemento_requisicion_seleccionado}
                                proveedor={proveedor} 

       />
      )

  }


    </>
  );
}


export default ModalNuevaRequisicion;