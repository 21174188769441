import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'


import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'


import ModalVehiculoRegistro from './ModalVehiculoRegistro'
import ModalVehiculoEditar from './ModalVehiculoEditar'



import ModalVehiculoCargaFotos from './ModalVehiculoCargaFotos'


import ModalVehiculoAsignar from './ModalVehiculoAsignar'


import ModalInformacionAsignamientosVehiculo from './ModalInformacionAsignamientosVehiculo'


import ModalHistoricoAsignamientos from './ModalHistoricoAsignamientos'


import { SERVER_ } from '../../config'


import './vehiculos.scss'


const Vehiculos = () =>{


  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 15
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`vehiculos/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
  }

      React.useEffect(() => {
        busqueda()
      }, []);


  const [ arreglo_estatus, setEstatus ] = useState<any>([true])


  const todos = () =>{
    setEstatus([true])
  }
  const ninguno = () =>{
    setEstatus([false])
  }






  const [ show_ModalVehiculoRegistro, setModalVehiculoRegistro ] = useState<boolean>(false)
  const handleShow_ModalVehiculoRegistro = () => {
    setModalVehiculoRegistro(true)
  }
  const handleClose_ModalVehiculoRegistro = () => {
    busqueda()
    setModalVehiculoRegistro(false)
  }




  const [ vehiculo_seleccionado, setVehiculoSeleccionado ] = useState<any>(null)

  const [ show_ModalVehiculoCargaFotos, setModalVehiculoCargaFotos ] = useState<boolean>(false)
  const handleShow_ModalVehiculoCargaFotos = (vehiculo:any) => {
    setVehiculoSeleccionado(vehiculo)
    setModalVehiculoCargaFotos(true)
  }
  const handleClose_ModalVehiculoCargaFotos = () => {
    busqueda()
    setModalVehiculoCargaFotos(false)
  }



  const [ show_ModalVehiculoEditar, setModalVehiculoEditar ] = useState<boolean>(false)
  const handleShow_ModalVehiculoEditar = (vehiculo:any) => {
    setVehiculoSeleccionado(vehiculo)
    setModalVehiculoEditar(true)
  }
  const handleClose_ModalVehiculoEditar = () => {
    busqueda()
    setModalVehiculoEditar(false)
  }




  const [ show_ModalVehiculoAsignar, setModalVehiculoAsignar ] = useState<boolean>(false)
  const handleShow_ModalVehiculoAsignar = (vehiculo:any) => {
    setVehiculoSeleccionado(vehiculo)
    setModalVehiculoAsignar(true)
  }
  const handleClose_ModalVehiculoAsignar = () => {
    busqueda()
    setModalVehiculoAsignar(false)
  }


  const [ show_ModalInformacionAsignamientosVehiculo, setModalInformacionAsignamientosVehiculo ] = useState<boolean>(false)
  const handleShow_ModalInformacionAsignamientosVehiculo = (vehiculo:any) => {
    setVehiculoSeleccionado(vehiculo)
    setModalInformacionAsignamientosVehiculo(true)
  }
  const handleClose_ModalInformacionAsignamientosVehiculo = () => {
    setModalInformacionAsignamientosVehiculo(false)
  }



  const [ show_ModalHistoricoAsignamientos, setModalHistoricoAsignamientos ] = useState<boolean>(false)
  const handleShow_ModalHistoricoAsignamientos = (vehiculo:any) => {
    setVehiculoSeleccionado(vehiculo)
    setModalHistoricoAsignamientos(true)
  }
  const handleClose_ModalHistoricoAsignamientos = () => {
    setModalHistoricoAsignamientos(false)
  }

      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Control Vehicular</h4>
                    <hr/>




                      <div className="row">
                      
                          <div className="mb-2">
                              <label className="form-label">Búsqueda</label>
                              <div className="hstack gap-3">
                                <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                                <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                                <div className="vr"></div>
                                <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                              </div>
                          </div>
                          
                        <ul className="list-inline">
                          <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
                          <li className="list-inline-item"><small className="pointer" onClick={handleShow_ModalVehiculoRegistro}>Registro Vehículo</small></li>
                        </ul>

                      </div>







                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg" border={0}>
                                          <thead>
                                            <tr>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                              <th scope="col">Marca</th>
                                              <th scope="col">Modelo</th>
                                              <th scope="col">Color</th>
                                              <th scope="col">Placas</th>
                                              <th scope="col">Motor</th>
                                              <th scope="col">Asignado</th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (vehiculo:any,indice:number) =>
                                                  <tr key={indice} className={` ${ vehiculo.activo ? '' : 'vehiculos_activos' } `} style={{fontSize:12}}>

                                                  
                                                    <td onClick={(e:any)=>{handleShow_ModalVehiculoCargaFotos(vehiculo)}} className="v text-left"  style={{"width":"70px"}} >
                                                        <img src={`${SERVER_}vehiculo/fotos/foto_frente/${vehiculo.uuid_vehiculo}/${Math.random().toString(36).substr(2, 9)}`} height="60px" />
                                                    </td>

                                                    <td onClick={(e:any)=>{handleShow_ModalVehiculoCargaFotos(vehiculo)}} className="v text-left"  style={{"width":"70px"}} >
                                                        <img src={`${SERVER_}vehiculo/fotos/foto_lateral_1/${vehiculo.uuid_vehiculo}/${Math.random().toString(36).substr(2, 9)}`} height="60px" />
                                                    </td>

                                                    <td onClick={(e:any)=>{handleShow_ModalVehiculoCargaFotos(vehiculo)}} className="v text-left"  style={{"width":"70px"}} >
                                                        <img src={`${SERVER_}vehiculo/fotos/foto_lateral_2/${vehiculo.uuid_vehiculo}/${Math.random().toString(36).substr(2, 9)}`} height="60px" />
                                                    </td>

                                                    <td onClick={(e:any)=>{handleShow_ModalVehiculoCargaFotos(vehiculo)}} className="v text-left"  style={{"width":"70px"}} >
                                                        <img src={`${SERVER_}vehiculo/fotos/foto_trasera/${vehiculo.uuid_vehiculo}/${Math.random().toString(36).substr(2, 9)}`} height="60px" />
                                                    </td>


                                                    <td className="v text-left" >
                                                      <p className="nm"><b>{vehiculo.marca}</b></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"180px"}}>
                                                      <p className="nm">{vehiculo.modelo}</p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"180px"}}>
                                                      <p className="nm">{vehiculo.color}</p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"180px"}}>
                                                      <p className="nm">{vehiculo.placas}</p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"180px"}}>
                                                      <p className="nm">{vehiculo.numero_motor}</p>
                                                    </td>
                                                    <td onClick={(e:any)=>{handleShow_ModalInformacionAsignamientosVehiculo(vehiculo)}} className="v text-left" style={{"width":"180px", textAlign:'center'}}>
                                                      { vehiculo.idusuario_asignado && ( <p className="nm"><b>{vehiculo.idusuario_asignado.nombres} {vehiculo.idusuario_asignado.paterno} {vehiculo.idusuario_asignado.materno}</b></p> ) }
                                                      { !vehiculo.idusuario_asignado && ( <p className="nm"><i className="fa fa-info-circle"/></p> ) }
                                                    </td>


                                                    <td className="v text-left" style={{"width":"40px"}}>
                                                      <button type="button" onClick={(e:any)=>{handleShow_ModalHistoricoAsignamientos(vehiculo)}} className="btn btn-sm btn-link">📋</button>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"40px"}}>
                                                      <button type="button" onClick={(e:any)=>{handleShow_ModalVehiculoAsignar(vehiculo)}} className="btn btn-sm btn-info"><i className="fa fa-user"/></button>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"40px"}}>
                                                      <button type="button" onClick={(e:any)=>{handleShow_ModalVehiculoEditar(vehiculo)}} className="btn btn-sm btn-warning"><i className="fa fa-edit"/></button>
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}

                    
                </div>
              </div>

            </div>



  {
    show_ModalVehiculoRegistro && (
      <ModalVehiculoRegistro show={show_ModalVehiculoRegistro} handleClose={handleClose_ModalVehiculoRegistro}  />
      )

  }


  {
    show_ModalVehiculoCargaFotos && (
      <ModalVehiculoCargaFotos show={show_ModalVehiculoCargaFotos} handleClose={handleClose_ModalVehiculoCargaFotos} vehiculo={vehiculo_seleccionado}  />
      )

  }


  {
    show_ModalVehiculoEditar && (
      <ModalVehiculoEditar show={show_ModalVehiculoEditar} handleClose={handleClose_ModalVehiculoEditar} vehiculo={vehiculo_seleccionado} />
      )

  }



  {
    show_ModalVehiculoAsignar && (
      <ModalVehiculoAsignar show={show_ModalVehiculoAsignar} handleClose={handleClose_ModalVehiculoAsignar} vehiculo={vehiculo_seleccionado}  />
      )

  }



  {
    show_ModalInformacionAsignamientosVehiculo && (
      <ModalInformacionAsignamientosVehiculo show={show_ModalInformacionAsignamientosVehiculo} handleClose={handleClose_ModalInformacionAsignamientosVehiculo} vehiculo={vehiculo_seleccionado} />
      )

  }


  {
    show_ModalHistoricoAsignamientos && (
      <ModalHistoricoAsignamientos show={show_ModalHistoricoAsignamientos} handleClose={handleClose_ModalHistoricoAsignamientos} vehiculo={vehiculo_seleccionado} />
      )

  }

          </>

      );
  

}

export default Vehiculos
