import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AuthService from '../../features/auth/auth.service';

import RequestService from "../../services/request.service";

import Dialog from '../../services/Dialogs'; // Asegúrate de tener el import correcto de tu Dialog

import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';



const ModalConfiguracionBitacora = (params: any) => {
  const user = AuthService.getCurrentUser();
  const { show, handleClose, tipo_equipo } = params;

  const [check_list_bitacoras, setCheckListBitacoras] = useState<any>([ ...tipo_equipo.check_list_bitacoras ]);

  const handleRemove = (indice: number) => {
    const updatedList = check_list_bitacoras.filter((_:any, index:number) => index !== indice);
    setCheckListBitacoras(updatedList);
    toast.success("Elemento eliminado");
  };

  const handleDragEnd = (result: any) => {
    const { destination, source } = result;

    if (!destination) return; // Si no hay destino, no hacemos nada.

    // Si el elemento se movió a otro lugar
    if (destination.index !== source.index) {
      const updatedList = Array.from(check_list_bitacoras);
      const [removed] = updatedList.splice(source.index, 1);
      updatedList.splice(destination.index, 0, removed);

      setCheckListBitacoras(updatedList);
      toast.success("Lista reordenada");
    }
  };



const sendCheckListToServer = async () => {
  try {
    // Primero, mostramos un cuadro de confirmación al usuario antes de enviar los datos
    const userConfirmed = await new Dialog().confirm(
      '¿Estás seguro?',
      { accept: 'Sí', cancel: 'No' }
    );

    // Si el usuario confirmó, procedemos con el envío
    if (userConfirmed) {
      // Realizamos el envío de los datos al servidor
      try {
        const response = await RequestService.update(`equipo_critico/registro_bitacoras/${tipo_equipo.id}`, { check_list_bitacoras : check_list_bitacoras});
        
        // Si la respuesta es exitosa, mostramos un mensaje de éxito
        if (response.status === 200) {
          toast.success('Lista de bitácoras enviada correctamente');
        } else {
          toast.error('Hubo un problema al enviar la lista de bitácoras');
        }
      } catch (error) {
        // Si ocurre un error en el request, mostramos un mensaje de error
        console.error('Error al enviar los datos:', error);
        toast.error('Error al enviar la lista de bitácoras. Intenta nuevamente.');
      }
    } else {
      // Si el usuario cancela, mostramos un mensaje informando que no se enviaron los datos
      toast.info('El envío fue cancelado.');
    }
  } catch (error) {
    // Si ocurre algún error con el diálogo de confirmación, mostramos un error general
    console.error('Error en la confirmación:', error);
    toast.error('Hubo un error en la confirmación. Intenta nuevamente.');
  }
};

  const [ agregar_elemento_modal, setAgregarElementoModal ] = useState<any>(false)
  const [ tipo_elemento_agregar, setTipoElementoAgregar ] = useState<any>(0)


  const agregarElemento = (tipo:number) => {
    setTipoElementoAgregar(tipo)
    setAgregarElementoModal(true)
  }


  const mensajes_validacion_generico = "Este campo es obligatorio"
  // const schema_elemento = ;



  const enviarFormulario = async (registro:any,event:any) =>{
    let actual_ = [ ...check_list_bitacoras ]
    actual_.push({...registro, opciones : registro.opciones.split(',')})
    setCheckListBitacoras(actual_)
    setAgregarElementoModal(false)

  }





  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Configuración para Bitacora</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h5 className="text-center mb-4">{tipo_equipo.tipo_equipo}</h5>

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided:any) => (
                <ol className="list-group list-group-numbered" ref={provided.innerRef} {...provided.droppableProps}>
                  {check_list_bitacoras.map((registro: any, indice_registro: any) => (
                    <Draggable key={indice_registro} draggableId={String(indice_registro)} index={indice_registro}>
                      {(provided:any) => (
                        <li
                          className="list-group-item"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          key={indice_registro}
                        >
                          {registro.descripcion}<br />
                          <small><b>Tipo: </b></small> {registro.tipo === 0 ? 'Selección de Opción' : 'Ingreso de Valor'}<br />
                          {registro.tipo === 0 && (
                            <><small><b>Opciones: </b></small> {registro.opciones.join(', ')}</>
                          )}
                          <Button 
                            variant="danger" 
                            size="sm" 
                            onClick={() => handleRemove(indice_registro)} 
                            style={{ float: 'right', marginTop: '-50px' }}
                          >
                            <i className="fa fa-trash" />
                          </Button>
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ol>
              )}
            </Droppable>
          </DragDropContext>
        </Modal.Body>
        <Modal.Footer>
          <div>
          <Button variant="warning" onClick={(e:any)=>{agregarElemento(0)}}>Selección de Opción</Button>{' '}
          <Button variant="warning" onClick={(e:any)=>{agregarElemento(1)}}>Ingreso de Valor</Button>
          </div>
          <div>
          <Button variant="secondary" onClick={handleClose}>Cerrar</Button>{' '}
          <Button variant="success" onClick={sendCheckListToServer} type="button">Guardar</Button>
          </div>
        </Modal.Footer>
      </Modal>






      {
        agregar_elemento_modal && (

            <Modal show={agregar_elemento_modal} onHide={()=>{setAgregarElementoModal(false)}}>
              <Modal.Header closeButton>
                <Modal.Title>Agregar Elemento</Modal.Title>
              </Modal.Header>

              <Modal.Body>


                  <Formik
                    validationSchema={yup.object().shape({
                              descripcion : yup.string().required(mensajes_validacion_generico),
                              tipo : yup.number(),
                              opciones : tipo_elemento_agregar === 1 ? yup.string() : yup.string().required(mensajes_validacion_generico),
                      })}
                    onSubmit={(values,e)=>{enviarFormulario(values, e)}}
                    initialValues={{
                          descripcion : "",
                          tipo : tipo_elemento_agregar,
                          opciones : ""

                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      setFieldValue,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                    }) => (
                      <Form id='my-form-2' noValidate onSubmit={handleSubmit}>

                      {
                        // JSON.stringify(values.descripcion)
                      }
                      {
                        // JSON.stringify(values.opciones)
                      }

                          <Form.Group className="mb-3" controlId="campo1">
                            <Form.Label>Descripción</Form.Label>
                            <Form.Control
                              required
                              name="descripcion"
                              value={values.descripcion}
                              onBlur={(e)=>{ setFieldValue('descripcion', e.target.value ) }}
                              onChange={(e)=>{ setFieldValue('descripcion', e.target.value ) }}
                              isInvalid={!!errors.descripcion}
                            />
                            <Form.Control.Feedback type="invalid"> {<>{errors.descripcion}</>} </Form.Control.Feedback>
                          </Form.Group>

                        {
                          tipo_elemento_agregar === 0 && (

                              <Form.Group className="mb-3" controlId="campo1">
                                <Form.Label>Opciones</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  required
                                  name="opciones"
                                  value={values.opciones}
                                  onBlur={(e)=>{ setFieldValue('opciones', e.target.value ) }}
                                  onChange={(e)=>{ setFieldValue('opciones', e.target.value ) }}
                                  isInvalid={!!errors.opciones}
                                />
                                <Form.Control.Feedback type="invalid"> {<>{errors.opciones}</>} </Form.Control.Feedback>
                              </Form.Group>

                            )
                        }



                      </Form>
                    )}
                  </Formik>



              </Modal.Body>

              <Modal.Footer>
                <Button variant="success" form='my-form-2' type="submit">Agregar <i className="fa fa-save"/></Button>
              </Modal.Footer>
            </Modal>

          )
      }






    </>
  );
};

export default ModalConfiguracionBitacora;
