import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import Dialog from '../../services/Dialogs'


import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';


const ModalAgregarTipoEquipo = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose,tipo_equipo } = params


  const dialog = new Dialog();

  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
      tipo_equipo : yup.string().required(mensajes_validacion_generico),
  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.create( `equipo_critico` , { ...registro })

          if(res_){
            toast.success('El Tipo de Equipo fué creado correctamente')
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }




  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Editar Tipo de Equipo</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {
            // JSON.stringify(tipo_equipo)
          }


          <Formik
            validationSchema={schema}
            onSubmit={(values,e)=>{enviarFormulario(values, e)}}
            initialValues={{

                  tipo_equipo : "",

            }}
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form id='my-form' noValidate onSubmit={handleSubmit}>


                  <Form.Group className="mb-3" controlId="tipo_equipo">
                    <Form.Label>Tipo de Equipo</Form.Label>
                    <Form.Control
                      required
                      name="tipo_equipo"
                      value={values.tipo_equipo}
                      onBlur={(e)=>{ setFieldValue('tipo_equipo', e.target.value.toUpperCase() ) }}
                      onChange={(e)=>{ setFieldValue('tipo_equipo', e.target.value ) }}
                      isInvalid={!!errors.tipo_equipo}
                      style={{textTransform:'uppercase'}}
                    />
                    <Form.Control.Feedback type="invalid"> {<>{errors.tipo_equipo}</>} </Form.Control.Feedback>
                  </Form.Group>

              </Form>
            )}
          </Formik>




        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalAgregarTipoEquipo;