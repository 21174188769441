import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'

import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'


import ModalAgregarTipoEquipo from './ModalAgregarTipoEquipo'
import ModalEditarTipoEquipo from './ModalEditarTipoEquipo'


import ModalConfiguracionBitacora from './ModalConfiguracionBitacora'
import ModalConfiguracionSupervision from './ModalConfiguracionSupervision'



const TiposEquiposCriticos = () =>{


  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 30
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`tipo_equipo_critico/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e:any){

      if (e.response?.status) {
        switch (e.response.status) {
          case 404:
            toast.error("Debes escribir algo")
            break;
          default:
            toast.error(e.response?.data)
        }
      }
      else{
        toast.error("Problemas con la busqueda")
      }
      setCargando(false)

    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }


      React.useEffect(() => {
        busqueda()
      }, []);



  const [ show_ModalAgregarTipoEquipo, setModalAgregarTipoEquipo ] = useState<boolean>(false)
  const handleShow_ModalAgregarTipoEquipo = () => {
    setModalAgregarTipoEquipo(true)
  }
  const handleClose_ModalAgregarTipoEquipo = () => {
    setModalAgregarTipoEquipo(false)
  }



  const [ select_tipo_equipo, setSelectTipoEquipo ] = useState<any>(null)
  const [ show_ModalEditarTipoEquipo, setModalEditarTipoEquipo ] = useState<boolean>(false)
  const handleShow_ModalEditarTipoEquipo = (tipo_equipo:any) => {
    setSelectTipoEquipo(tipo_equipo)
    setModalEditarTipoEquipo(true)
  }
  const handleClose_ModalEditarTipoEquipo = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalEditarTipoEquipo(false)
  }



  const [ show_ModalConfiguracionBitacora, setModalConfiguracionBitacora ] = useState<boolean>(false)
  const handleShow_ModalConfiguracionBitacora = (tipo_equipo:any) => {
    setSelectTipoEquipo(tipo_equipo)
    setModalConfiguracionBitacora(true)
  }
  const handleClose_ModalConfiguracionBitacora = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalConfiguracionBitacora(false)
  }



  const [ show_ModalConfiguracionSupervision, setModalConfiguracionSupervision ] = useState<boolean>(false)
  const handleShow_ModalConfiguracionSupervision = (tipo_equipo:any) => {
    setSelectTipoEquipo(tipo_equipo)
    setModalConfiguracionSupervision(true)
  }
  const handleClose_ModalConfiguracionSupervision = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalConfiguracionSupervision(false)
  }



      return (
          <>


                <div className="row">
                
                    <div className="mb-2">
                        <label className="form-label">Búsqueda</label>
                        <div className="hstack gap-3">
                          <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                          <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                          <div className="vr"></div>
                          <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                        </div>
                    </div>
                    

                    <ul className="list-inline">
                      <li className="list-inline-item"><small className="pointer" onClick={()=>{handleShow_ModalAgregarTipoEquipo()}}>Agregar</small></li>
                    </ul>


                </div>



              {  (!cargando_) && (

                  <>

                       {
                         resultados.length > 0 && (

                           <>


                           <h5>Resultados</h5>


                            <Table responsive="lg">
                              <thead>
                                <tr>
                                  <th scope="col">Tipo de Equipo</th>
                                  <th scope="col">Edición</th>
                                  <th scope="col">Bitacora</th>
                                  <th scope="col">Supervisión</th>
                                </tr>
                              </thead>
                              <tbody>
                                {


                                  resultados.map( (tipo_equipo:any,indice_tipo_equipo:number) =>
                                      <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}}>
                                        <td className="v text-left" >
                                            <h3 className="nm"><b>{tipo_equipo.tipo_equipo}</b></h3>
                                        </td>
                                        <td className="v text-center" style={{"width":"60px"}}>
                                          <button type="button" onClick={(e:any)=>{handleShow_ModalEditarTipoEquipo(tipo_equipo)}} className="btn btn-link">📝</button>
                                        </td>
                                        <td className="v text-center" style={{"width":"60px"}}>
                                          <button type="button" onClick={(e:any)=>{handleShow_ModalConfiguracionBitacora(tipo_equipo)}} className="btn btn-link">📓</button>
                                        </td>
                                        <td className="v text-center" style={{"width":"60px"}}>
                                          <button type="button" onClick={(e:any)=>{handleShow_ModalConfiguracionSupervision(tipo_equipo)}} className="btn btn-link">📓</button>
                                        </td>
                                      </tr>
                                   )
                                }

                              </tbody>
                            </Table>


                              <Pagination
                                itemsCount={allSessionsCount}
                                itemsPerPage={sessionsPerPage}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                cambiarPaginaEvento={cargarMiReporte}
                                alwaysShown={false}
                              />


                            </>



                           )
                         
                       }


                  </>

               )}

              {  cargando_ && (

                  <>
                    <div className="loader">
                      <div className="loaderBar"></div>
                    </div>
                  </>


               )}







  {
    show_ModalAgregarTipoEquipo && (
      <ModalAgregarTipoEquipo show={show_ModalAgregarTipoEquipo} handleClose={handleClose_ModalAgregarTipoEquipo}  />
      )

  }


  {
    show_ModalEditarTipoEquipo && (
      <ModalEditarTipoEquipo show={show_ModalEditarTipoEquipo} handleClose={handleClose_ModalEditarTipoEquipo} tipo_equipo={select_tipo_equipo} />
      )

  }


  {
    show_ModalConfiguracionBitacora && (
      <ModalConfiguracionBitacora show={show_ModalConfiguracionBitacora} handleClose={handleClose_ModalConfiguracionBitacora} tipo_equipo={select_tipo_equipo}  />
      )

  }

  {
    show_ModalConfiguracionSupervision && (
      <ModalConfiguracionSupervision show={show_ModalConfiguracionSupervision} handleClose={handleClose_ModalConfiguracionSupervision} tipo_equipo={select_tipo_equipo} />
      )

  }


          </>

      );
  

}

export default TiposEquiposCriticos
