import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Form, Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import Dialog from '../../services/Dialogs'

import moment from 'moment'



import { formatearNumero, condicionesPago, tipoActividadLabel, labelUnidadMedida, ordenarPorTipo, tipoPresupuesto } from '../../services/funcionesPublicas'


const ModalNuevaActividad = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params


  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          indicaciones : yup.string().required(mensajes_validacion_generico),
          fecha_programada : yup.string().required(mensajes_validacion_generico),
          idpresupuesto : yup.string().required(mensajes_validacion_generico).nullable(),
          idresponsable : yup.string(), //.required(mensajes_validacion_generico),
          idtecnico : yup.string().required(mensajes_validacion_generico),
          idtipo_orden : yup.string().required(mensajes_validacion_generico),

  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: `<small>La visita se creará</small>`
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.create( `calendario/nueva_actividad` , { ...registro, fecha_programada : moment(registro.fecha_programada).format('YYYY-MM-DD') })

          if(res_){
            toast.success('La actividad fue asignada correctamente')
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }


  const [ presupuestos, setPresupuestos ] = useState<any>([])
  const cargarPresupuestos = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`presupuestos_nueva_actividad`)
      setPresupuestos(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarPresupuestos()
    cargarLista_responsables()
    cargarLista_tecnicos()
    cargarTipo_orden_trabajo()
  }, []);




  const [ lista_responsables, setLista_responsables ] = useState<any>({
    mantenimiento : []
  })
  const cargarLista_responsables = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`zonas/lista_responsables`)
      setLista_responsables(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


  const [ lista_quien_atiende, setLista_tecnicos ] = useState<any>({
      tecnicos : [],
      conservacion : [],
      limpieza : [],
  })
  const cargarLista_tecnicos = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`zonas/lista_tecnicos`)
      setLista_tecnicos(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }




  const [ tipo_orden_trabajo, setTipo_orden_trabajo ] = useState<any>([])
  const cargarTipo_orden_trabajo = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/tipo_orden_trabajo`)
      setTipo_orden_trabajo(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }



  // const [ costo_servicio, setCostoServicio ] = useState<number>(0)

  const [ presupuesto, setPresupuestoView ] = useState<any>(null)
    

  const [ suma_subtotal, setSuma ] = useState<number>(0)

  const sumarSubtotales = (presupuesto:any) =>{
    let s = 0
    // console.log(presupuesto.material_actividades)
    for(let actividad of presupuesto.material_actividades){
      s += ( actividad.costo * actividad.cantidad )
    }
    setSuma(s)

  }


  const [ tipo_unidad_medida, setTipo_unidad_medida ] = useState<any>([])
  const cargarTipo_unidad_medida = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/tipo_unidad_medida`)
      setTipo_unidad_medida(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }




const confirmarRegresarPresupuestos = async(e:any, uuid_presupuesto:any) => {
    try{

        await dialog.open({
          accept: 'Si',
          cancel: 'No',
          dialogClass: '',
          message: 'Estás seguro',
          target: e.target,
          template: `
          `
        })
        let dialogo : any = await dialog.waitForUser()
        
        if(dialogo){
          let res = await RequestService.update(`operacion/presupuesto/regresar_presupuesto/${uuid_presupuesto}`,{})
          if(res.data){
            toast.warning('El Presupuesto se mandó al Departamento de Presupuestos')
            setPresupuestoView(null); 
            await cargarPresupuestos()
          }
          
        }

    }
    catch(e){
      toast.error('Tuvimos problemas....')
    }

  }



  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Nueva Actividad Presupuestada</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }

          <Formik
            validationSchema={schema}
            onSubmit={(values,e)=>{enviarFormulario(values, e)}}
            initialValues={{

                  indicaciones : "",
                  idpresupuesto : "",
                  idresponsable : ["ROLE_ADMIN","ROLE_RESPONSABLE_OPERACION"].indexOf(user.rol) > -1 ? "" : user.uuid,
                  idtecnico : "",
                  idtipo_orden : "",
                  fecha_programada : new Date(),

            }}
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form id='my-form' noValidate onSubmit={handleSubmit}>



















<div className="row">
  <div className="col-sm-4">





              {
                // (values.idpresupuesto)
              }


                         <Form.Group className="mb-3" controlId="idpresupuesto">
                          <Form.Label>Selecciona un Presupuesto  </Form.Label>
                          <Form.Select required name="idpresupuesto" value={values.idpresupuesto} onChange={(e)=>{ 
                            if(e.target.value !==''){ setFieldValue('idpresupuesto', e.target.value ); setPresupuestoView(JSON.parse(e.target.value)); sumarSubtotales(JSON.parse(e.target.value)); }
                            else { setFieldValue('idpresupuesto', null ); setPresupuestoView(null); }
                          }} isInvalid={!!errors.idpresupuesto}>
                            <option value="">SELECCIONA UN PRESUPUESTO</option>
                              {
                                presupuestos.map((presupuesto:any)=>
                                    <option key={ Math.random().toString(36).substr(2, 9) } value={JSON.stringify(presupuesto)}>{presupuesto.folio} - {presupuesto.idboutique.nombre_boutique}</option>
                                  )
                              }
                          </Form.Select>
                          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid"> {<>{errors.idpresupuesto}</>} </Form.Control.Feedback>
                        </Form.Group>



                        {
                          presupuesto && (

                              <>



                  <Form.Group className="mb-3" controlId="campo1">
                    <Form.Label>Indicaciones para la Actividad</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      required
                      name="indicaciones"
                      value={values.indicaciones}
                      onBlur={(e)=>{ setFieldValue('indicaciones', e.target.value.toUpperCase() ) }}
                      onChange={(e)=>{ setFieldValue('indicaciones', e.target.value ) }}
                      style={{textTransform:'uppercase'}}
                      isInvalid={!!errors.indicaciones}
                    />
                    <Form.Control.Feedback type="invalid"> {<>{errors.indicaciones}</>} </Form.Control.Feedback>
                  </Form.Group>



                  <div className="row">
                    <div className="">
                        <div className="mb-3">
                          <Form.Label>Fecha</Form.Label>
                          <DatePicker selected={values.fecha_programada} className="form-control"  dateFormat="yyyy-MM-dd"  onChange={(date) => setFieldValue('fecha_programada', date ) } />
                        </div>
                    </div>
                  </div>

{
  // presupuesto.tipo_presupuesto
}
{
// 'GASTO_OPERACION'
// 'GASTOS_MOVILIZACION'
// 'PRESUPUESTO_CONSERVACION'
// 'PRESUPUESTO_INSTALACION'
// 'PRESUPUESTO_REPARACION'
// 'PRESUPUESTO_SUMINISTRO'
// 'PRESUPUESTO_SUMINISTRO_INSTALACION'
}


                         <Form.Group className="mb-3" controlId="idtipo_orden">
                          <Form.Label>Tipo de Orden de Trabajo</Form.Label>
                          <Form.Select required name="idtipo_orden" value={values.idtipo_orden} onChange={(e)=>{ setFieldValue('idtipo_orden', e.target.value ) }} isInvalid={!!errors.idtipo_orden}>
                            <option value="">SELECCIONA UN TIPO DE ORDEN</option>
                              {
                                tipo_orden_trabajo.map((tipo_orden:any,indice_tipo_orden:number)=>
                                    <>
                                    {
                                      [1,4].indexOf(tipo_orden.id) === -1 && <option key={indice_tipo_orden} value={tipo_orden.id}>{tipo_orden.tipo_orden} {tipo_orden.id} </option>
                                    }
                                    </>
                                  )
                              }
                          </Form.Select>
                          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid"> {<>{errors.idtipo_orden}</>} </Form.Control.Feedback>
                        </Form.Group>

                        {
                          // values.idpresupuesto
                        }

                        <h5>Responsables de la Actividad</h5>


                        {

                          ["ROLE_RESPONSABLE_OPERACION","ROLE_ADMIN"].indexOf(user.rol) > -1 && (
                              <React.Fragment>



                                 <Form.Group className="mb-3" controlId="idresponsable">
                                  <Form.Label>Selecciona un Responsable</Form.Label>
                                  <Form.Select required name="idresponsable" value={values.idresponsable} onChange={(e)=>{ setFieldValue('idresponsable', e.target.value ) }} isInvalid={!!errors.idresponsable}>
                                    <option value="">SELECCIONA UN RESPONSABLE</option>
                                      {
                                        lista_responsables.mantenimiento.map((responsable:any)=>
                                            <option key={ Math.random().toString(36).substr(2, 9) } value={responsable.uuid}>{responsable.nombres} {responsable.paterno} {responsable.materno} </option>
                                          )
                                      }
                                  </Form.Select>
                                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                  <Form.Control.Feedback type="invalid"> {<>{errors.idresponsable}</>} </Form.Control.Feedback>
                                </Form.Group>


                              </React.Fragment> 
                            )

                        }




                         <Form.Group className="mb-3" controlId="idtecnico">
                          <Form.Label>Selecciona a quien realizará la Actividad</Form.Label>
                          <Form.Select required name="idtecnico" value={values.idtecnico} onChange={(e)=>{ setFieldValue('idtecnico', e.target.value ) }} isInvalid={!!errors.idtecnico}>
                            <option value="">SELECCIONA UNA OPCIÓN</option>

                            {
                              ["1","2","3","8"].indexOf(values.idtipo_orden) > -1 && (
                                  <>
                                    {
                                      lista_quien_atiende.tecnicos.map((tecnico:any)=>
                                          <option key={ Math.random().toString(36).substr(2, 9) } value={tecnico.uuid}>{tecnico.nombres} {tecnico.paterno} {tecnico.materno} </option>
                                        )
                                    }
                                  </>
                                )
                            }

                            {
                              ["7"].indexOf(values.idtipo_orden) > -1 && (
                                  <>
                                    {
                                      lista_quien_atiende.limpieza.map((tecnico:any)=>
                                          <option key={ Math.random().toString(36).substr(2, 9) } value={tecnico.uuid}>{tecnico.nombres} {tecnico.paterno} {tecnico.materno} </option>
                                        )
                                    }
                                  </>
                                )
                            }

                            {
                              ["4","5","6", "10","11"].indexOf(values.idtipo_orden) > -1 && (
                                  <>
                                    {
                                      lista_quien_atiende.conservacion.map((tecnico:any)=>
                                          <option key={ Math.random().toString(36).substr(2, 9) } value={tecnico.uuid}>{tecnico.nombres} {tecnico.paterno} {tecnico.materno} </option>
                                        )
                                    }

                                    {
                                      lista_quien_atiende.tecnicos.map((tecnico:any)=>
                                          <option key={ Math.random().toString(36).substr(2, 9) } value={tecnico.uuid}>{tecnico.nombres} {tecnico.paterno} {tecnico.materno} </option>
                                        )
                                    }
                                  </>
                                )
                            }



                          </Form.Select>
                          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid"> {<>{errors.idtecnico}</>} </Form.Control.Feedback>
                        </Form.Group>




                              </>

                            )
                        }







                        {
                          // values.idpresupuesto
                        }


 </div>
  <div className="col-sm-8">
    
    {
      presupuesto && (

          <>

          {
            presupuesto.idestatus_presupuesto.id < 4 && (

                <>

                    <h5 className="mt-2">Folio</h5>
                    <p>{presupuesto.folio}</p>

                    <h5 className="mt-2">Boutique</h5>
                    <p className="nm">{presupuesto.idboutique.nombre_boutique}</p>
                    {
                      presupuesto.solicitado && (<p className="nm"><i className="fa fa-star"/> Presupuesto solicitado por la Boutique</p>)
                    }

                    <h5 className="mt-2">Descripción General</h5>
                    <p>{presupuesto.descripcion}</p>


                    <h6 className="mt-2">Tiempo Estimado de la Obra</h6>
                    <p>{presupuesto.dias_finaliza_estimados} Día(s)</p>


                                        {
                                          // detalles_visita.idpresupuesto && (
                                          //     <React.Fragment>

                                                
                                                
                                          //       {
                                          //         detalles_visita.idpresupuesto.materiales_actividades.length > 0 && (
                                          //               <small>Materiales usados en el servicio</small>
                                          //           )
                                          //       }
                                          //          {
                                          //           detalles_visita.idpresupuesto.materiales_actividades.length > 0 && (
                                          //             <table className="table table-sm mt-2" style={{fontSize:12}}>
                                          //               <thead>
                                          //                 <tr>
                                          //                   <th scope="col">Descripción</th>
                                          //                   <th scope="col">Unidad</th>
                                          //                   <th scope="col">Cantidad</th>
                                          //                 </tr>
                                          //               </thead>
                                          //               <tbody>
                                          //               {
                                          //                 [...detalles_visita.idpresupuesto.materiales_actividades].sort(ordenarPorTipo).map((material:any,index:number)=>


                                          //                 <tr key={ Math.random().toString(36).substr(2, 9) }> 
                                          //                   <td>
                                          //                     <p className="nm"><small>{material.descripcion}</small></p>
                                          //                   </td>
                                          //                   <td className="v" style={{width:120}}>
                                          //                     <p className="nm"><small>{ material.idunidad_medida.unidad_medida}</small></p>
                                          //                   </td>
                                          //                   <td className="v" style={{width:80}}>
                                          //                     <p className="nm text-right">{formatearNumero(parseFloat(material.cantidad)||0)}</p>
                                          //                   </td>  
                                          //                 </tr>

                                          //                   )
                                          //               }


                                          //               </tbody>
                                          //             </table>
                                          //             )
                                          //         }

                                          //     </React.Fragment>
                                          //   )
                                        }



                </>

              )
          }

          {
            presupuesto.idestatus_presupuesto.id > 3 && (

                <>

        <div className="row">
          <div className="col-sm-12">
          {
            // JSON.stringify(presupuesto)
          }

          <div className={`text-center ${presupuesto.tipo_presupuesto}`} style={{padding: '10px 0'}}  >
            <p className="nm"><small><b>{tipoPresupuesto(presupuesto.tipo_presupuesto)}</b></small></p>
          </div>

                <h5 className="mt-2">Descripción del Presupuesto</h5>
                <p>{presupuesto.descripcion}</p>


          <div className="row">
            <div className="col-sm-4">
                <h5 className="mt-2">Folio</h5>
                <p>{presupuesto.folio}</p>
            </div>
            <div className="col-sm-8">
                <h5 className="mt-2">Boutique</h5>
                <p className="nm">{presupuesto.idboutique.nombre_boutique}</p>
            </div>
          </div>


{
  // JSON.stringify(presupuesto.tipo_presupuesto)
}

          {
            presupuesto.solicitado === 1 && (<p className="nm"><i className="fa fa-star"/> Presupuesto solicitado por la Boutique</p>)
          }


          <h5 className="mt-2">Condiciones de Ejecución</h5>
          <p>{presupuesto.condiciones_ejecucion||'Ninguna en particular'}</p>



          <div className="row">
            <div className="col-sm-4">
                <h5 className="mt-2">Condiciones de Pago</h5>
                <p>{condicionesPago(presupuesto.tipo_condicion_pago)}</p>
            </div>
            <div className="col-sm-4">
                <h5 className="mt-2">Vigencia de Precios</h5>
                <p>{presupuesto.vigencia_dias_precios}</p>
            </div>
            <div className="col-sm-4">
                <h6 className="mt-2">Tiempo Estimado de la Obra</h6>
                <p>{presupuesto.dias_finaliza_estimados} Día(s)</p>
            </div>
          </div>





{
          // <h5 className="mt-2">Detalle de las Actividades a Realizar</h5>
          // <ol>
          //   {
          //     presupuesto.actividades_propuestas.map((actividad:any)=>
          //         <li>
          //           <p className="nm">{JSON.stringify(actividad)}</p>
          //         </li>
          //       )
          //   }
          // </ol>
}

            {
              // JSON.stringify(presupuesto.material_actividades)
            }

                  <h5 className="mt-2">Listado de Materiales y Servicios</h5>

                  {
                    presupuesto.material_actividades.length === 0 && (
                          <>
                            <div className="alert alert-warning" role="alert">
                              <p className="nm">🚨 Este presupuesto no tiene asignado ningún servicio o material por lo que no se puede asignar una visita hasta que el departamento de presupuestos asigne por lo menos un material o servicio al presupuesto.</p>
                              <p className="nm">Para regresar el control al departamento de presupuestos da <button onClick={(e:any)=>{confirmarRegresarPresupuestos(e,presupuesto.uuid_presupuesto)}} type="button" className="btn btn-sm btn-link">clic aquí</button></p>
                            </div>
                          </>
                      )
                  }


                  {
                    presupuesto.material_actividades.length > 0 && (

                            <table className="table table-sm" >
                              <thead>
                                <tr>
                                  <th scope="col">Tipo</th>
                                  <th scope="col">Descripción</th>
                                  <th scope="col">Unidad</th>
                                  <th scope="col">Cantidad</th>
                                </tr>
                              </thead>
                              <tbody>
                              {
                                [...presupuesto.material_actividades].sort(ordenarPorTipo).map((material:any,index:number)=>


                                <tr>
                                  <td className="v" style={{width:120}}>
                                    <p className="nm">
                                    {
                                      // JSON.stringify(material.idunidad_medida)
                                    }
                                    <small><b>{tipoActividadLabel(material.tipo)}</b></small>
                                    </p>
                                  </td>
                                  <td>
                                    <p className="nm"><small>{material.descripcion}</small></p>
                                  </td>
                                  <td className="v" style={{width:120}}>
                                    <p className="nm"><small>{material.idunidad_medida?.unidad_medida}</small></p>
                                  </td>
                                  <td className="v" style={{width:80}}>
                                    <p className="nm text-right">{formatearNumero(parseFloat(material.cantidad)||0)}</p>
                                  </td>
                                  
                                </tr>

                                  )
                              }

                              </tbody>
                            </table>



                      )
                  }


          </div>
        </div>



          
                </>

              )
          }


          </>

        )
    }


    
  </div>
</div>





              </Form>
            )}
          </Formik>



        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar Ventana
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalNuevaActividad;