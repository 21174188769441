import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap'

import { toast } from 'react-toastify'

import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import Dialog from '../../services/Dialogs'

import moment from 'moment'

import { tipoDeElementoRequisicion, filtrarElementosUnicos } from '../../services/funcionesPublicas'


const AgregarElementoRequisicion = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, tipo_elemento_requisicion, requisiciones_listado, setRequisicionListado, proveedor } = params



  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"


  const defaultSchema = (tipo:string)=>{

    let initialValues : any = proveedor ? 
                                {
                                  concepto : yup.string().required(mensajes_validacion_generico),
                                  monto_total : yup.number().required(mensajes_validacion_generico),
                                  con_factura : yup.boolean(),

                                } 
                                :
                                {
                                  concepto : yup.string().required(mensajes_validacion_generico),
                                  monto_total : yup.number().required(mensajes_validacion_generico),
                                  con_factura : yup.boolean(),

                                  banco : yup.string().required(mensajes_validacion_generico),
                                  cuenta_referencia : yup.string().required(mensajes_validacion_generico),
                                  nombre_razon_social : yup.string().required(mensajes_validacion_generico),
                                  detalles_deposito : yup.string(),
                              }
    // if(tipo==='compra'){
    //   initialValues = {

    //               ...initialValues,

    //               idunidad_medida : yup.number().required(mensajes_validacion_generico),
    //               cantidad : yup.number().required(mensajes_validacion_generico),
    //             } 
    // }

    return initialValues

  }


  const schema = yup.object().shape( defaultSchema(tipo_elemento_requisicion) );


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

            let values : any = proveedor ?
                                {
                                  concepto : registro.concepto, 
                                  idunidad_medida : registro.idunidad_medida,
                                  tipo : tipo_elemento_requisicion, 
                                  monto_total : registro.monto_total,
                                }
                                :
                                { 
                                  concepto : registro.concepto, 
                                  idunidad_medida : registro.idunidad_medida,
                                  tipo : tipo_elemento_requisicion, 
                                  monto_total : registro.monto_total,
                                  con_factura : registro.con_factura,


                                  meta_data : {
                                    banco : registro.banco,
                                    cuenta_referencia : registro.cuenta_referencia,
                                    nombre_razon_social : registro.nombre_razon_social,
                                    detalles_deposito : registro.detalles_deposito
                                  },


                                  banco : registro.banco,
                                  cuenta_referencia : registro.cuenta_referencia,
                                  nombre_razon_social : registro.nombre_razon_social
                                }

            // if(tipo_elemento_requisicion=='compra'){
            //   values = proveedor ?
            //                     {
            //                       concepto : registro.concepto, 
            //                       tipo : tipo_elemento_requisicion, 
            //                       monto_total : registro.monto_total, 
            //                       con_factura : true, 
            //                       idunidad_medida : registro.idunidad_medida, 
            //                       cantidad : registro.cantidad ,
            //                     }
            //                     :
            //                     { 
            //                       concepto : registro.concepto, 
            //                       tipo : tipo_elemento_requisicion, 
            //                       monto_total : registro.monto_total, 
            //                       con_factura : registro.con_factura, 
            //                       idunidad_medida : registro.idunidad_medida, 
            //                       cantidad : registro.cantidad ,


            //                       meta_data : {
            //                         banco : registro.banco,
            //                         cuenta_referencia : registro.cuenta_referencia,
            //                         nombre_razon_social : registro.nombre_razon_social,
            //                         detalles_deposito : registro.detalles_deposito
            //                       },

            //                       banco : registro.banco,
            //                       cuenta_referencia : registro.cuenta_referencia,
            //                       nombre_razon_social : registro.nombre_razon_social,
            //                       detalles_deposito : registro.detalles_deposito
            //                     }
            // }

            setRequisicionListado([ ...requisiciones_listado , values ])
            handleClose()

          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }


  const initialValuesDefault = (tipo:string)=>{

    let initialValues : any = {
                concepto : "",
                monto_total : "",
                con_factura : false,

                a_quien_depositar : "",
                banco : "",
                cuenta_referencia : "",
                nombre_razon_social : "",
                detalles_deposito : ""
          }

    // if(tipo==='compra'){
    //   initialValues = proveedor ? {
    //                     concepto : "",
    //                     monto_total : "",
    //                     con_factura : true,
    //                     idunidad_medida : "",
    //                     cantidad : "",
    //                   } 
    //                   : 
    //                 {
    //                   concepto : "",
    //                   monto_total : "",
    //                   con_factura : true,
    //                   idunidad_medida : "",
    //                   cantidad : "",

    //                   a_quien_depositar : "",
    //                   banco : "",
    //                   cuenta_referencia : "",
    //                   nombre_razon_social : "",
    //                   detalles_deposito : ""

    //             } 
    // }

    return initialValues

  }


  React.useEffect(() => {
    cargarTipo_unidad_medida()
  }, []);



  const [ tipo_unidad_medida, setTipo_unidad_medida ] = useState<any>([])
  const cargarTipo_unidad_medida = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/tipo_unidad_medida`)
      setTipo_unidad_medida(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Agregar Elemento de Requisición</Modal.Title>
        </Modal.Header>
        <Modal.Body>


    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={initialValuesDefault(tipo_elemento_requisicion)}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form-elemento' noValidate onSubmit={handleSubmit}>


              <div className="card">
                <div className="card-header bg-primary text-white">
                  <h6 className="mb-0">{tipoDeElementoRequisicion(tipo_elemento_requisicion)}</h6>
                </div>
                <div className="card-body">


                          <Form.Group className="mb-3" controlId="concepto">
                            <Form.Label>Concepto</Form.Label>
                            <Form.Control
                              as="textarea"
                              required
                              name="concepto"
                              value={values.concepto}
                              onBlur={(e)=>{ setFieldValue('concepto', e.target.value.toUpperCase() ) }}
                              onChange={(e)=>{ setFieldValue('concepto', e.target.value ) }}
                              isInvalid={!!errors.concepto}
                              style={{textTransform:'uppercase'}}
                            />
                            <Form.Control.Feedback type="invalid"> {<>{errors.concepto}</>} </Form.Control.Feedback>
                          </Form.Group>


                          <Form.Group className="mb-3" controlId="monto_total">
                            <Form.Label>Monto o Precio</Form.Label>
                            <Form.Control
                              required
                              name="monto_total"
                              value={values.monto_total}
                              onBlur={(e)=>{ setFieldValue('monto_total', e.target.value.toUpperCase() ) }}
                              onChange={(e)=>{ setFieldValue('monto_total', e.target.value ) }}
                              isInvalid={!!errors.monto_total}
                            />
                            <Form.Control.Feedback type="invalid"> {<>{errors.monto_total}</>} </Form.Control.Feedback>
                          </Form.Group>



                            {

                              // tipo_elemento_requisicion === 'compra' && (

                              //           <div className="row">
                              //               <Form.Group as={Col} sm={8} className="mb-3" controlId="idunidad_medida">
                              //                 <Form.Label>Unidad Medida</Form.Label>
                              //                 <Form.Select value={values.idunidad_medida} required onChange={(e)=>{ setFieldValue('idunidad_medida', e.target.value ) }}  isInvalid={!!errors.idunidad_medida}>
                              //                   <option value={''} >Selecciona una unidad de medida</option>
                              //                         {
                              //                           tipo_unidad_medida.map( (unidad_medida:any,indice_tipo_unidad_medida:number) =>
                              //                             <React.Fragment key={indice_tipo_unidad_medida}>
                                                                    
                              //                                       {
                              //                                         true && (<option selected={unidad_medida.id===values.idunidad_medida} value={unidad_medida.id}> {unidad_medida.unidad_medida}</option>)
                              //                                       } 
                              //                             </React.Fragment>
                              //                           )
                              //                         }
                              //                 </Form.Select>
                              //                 <Form.Control.Feedback type="invalid">{<>{errors.idunidad_medida}</>} </Form.Control.Feedback>
                              //               </Form.Group>

                              //             <Form.Group as={Col} sm={4} className="mb-3" controlId="cantidad">
                              //               <Form.Label>Cantidad</Form.Label>
                              //               <Form.Control 
                              //                 required
                              //                 name="cantidad"
                              //                 value={values.cantidad}
                              //                 onChange={(e)=>{ setFieldValue('cantidad', parseFloat(e.target.value) ) }}
                              //                 isInvalid={!!errors.cantidad}
                              //               />
                              //               <Form.Control.Feedback type="invalid"> {<>{errors.cantidad}</>} </Form.Control.Feedback>
                              //             </Form.Group>
                              //           </div>
                             

                              //   )

                            }



                </div>
              </div>



{
  !proveedor && (
    <>



              <div className="card mb-0">
                <div className="card-header bg-primary text-white">
                  <h6 className="mb-0">Información para Depósito, Transferencia o Transacción</h6>
                </div>
                <div className="card-body">



                        <div className="form-check mb-4">
                          <input className="form-check-input" type="checkbox" onChange={(e:any)=>{ setFieldValue('con_factura', e.target.checked ) }} checked={values.con_factura} id="con_factura" />
                          <label className="form-check-label" htmlFor="con_factura">
                            Se puede facturar
                          </label>
                        </div>


                        <Form.Group className="mb-3" controlId="nombre_razon_social">
                          <Form.Label>Nombre o Razón Social a quien se deposita</Form.Label>
                          <Form.Control 
                            required
                            name="nombre_razon_social"
                            value={values.nombre_razon_social}
                            onChange={(e)=>{ setFieldValue('nombre_razon_social', e.target.value.toUpperCase() ) }}
                            isInvalid={!!errors.nombre_razon_social}
                            style={{textTransform:'uppercase'}}
                          />
                          <Form.Control.Feedback type="invalid"> {<>{errors.nombre_razon_social}</>} </Form.Control.Feedback>
                        </Form.Group>


                        <Form.Group className="mb-3" controlId="banco">
                          <Form.Label>Banco o Entidad Financiera</Form.Label>
                          <Form.Control 
                            required
                            name="banco"
                            value={values.banco}
                            onChange={(e)=>{ setFieldValue('banco', e.target.value.toUpperCase() ) }}
                            isInvalid={!!errors.banco}
                            style={{textTransform:'uppercase'}}
                          />
                          <Form.Control.Feedback type="invalid"> {<>{errors.banco}</>} </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-" controlId="cuenta_referencia">
                          <Form.Label>Número de Cuenta, Referencia o Tarjeta</Form.Label>
                          <Form.Control 
                            required
                            name="cuenta_referencia"
                            value={values.cuenta_referencia}
                            onChange={(e)=>{ setFieldValue('cuenta_referencia', e.target.value ) }}
                            isInvalid={!!errors.cuenta_referencia}
                            style={{textTransform:'uppercase'}}
                          />
                          <Form.Control.Feedback type="invalid"> {<>{errors.cuenta_referencia}</>} </Form.Control.Feedback>
                        </Form.Group>

                        {
                          (values.nombre_razon_social !== ""  && values.banco !== "" && values.cuenta_referencia !== "") && (<button type="button" onClick={(e:any)=>{ setFieldValue('nombre_razon_social', "" ); setFieldValue('banco', "" ); setFieldValue('cuenta_referencia', "" ) }} className="mt-0 mb-0 btn btn-sm btn-link">Borrar captura</button>)
                        }


                        {
                          // JSON.stringify(filtrarElementosUnicos(requisiciones_listado.map((elemento:any)=>{ return elemento.meta_data })))
                        }
                        {
                          // JSON.stringify((requisiciones_listado))
                        }

                        {
                          requisiciones_listado.length > 0 && (
                              <>
                                <table className="table table-striped mt-2" style={{fontSize:10}}>
                                  <thead>
                                    <tr>
                                      <th>Banco</th>
                                      <th>Cuenta Referencia</th>
                                      <th>Nombre / Razón Social</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      filtrarElementosUnicos(requisiciones_listado.map((elemento:any)=>{ return elemento.meta_data })).map((item:any, index:number) => (
                                      // item.meta_data && (
                                        <tr onClick={(e:any)=>{ setFieldValue('nombre_razon_social', item.nombre_razon_social ); setFieldValue('banco', item.banco ); setFieldValue('cuenta_referencia', item.cuenta_referencia ) }} key={index}>
                                          <td>{item.banco || 'N/A'}</td>
                                          <td>{item.cuenta_referencia || 'N/A'}</td>
                                          <td>{item.nombre_razon_social || 'N/A'}</td>
                                        </tr>
                                      // )
                                    ))
                                    }
                                  </tbody>
                                </table>                                
                              </>
                            )
                        }






                      <Form.Group className="mb-3-" controlId="detalles_deposito">
                        <Form.Label>Detalles de para el movimiento</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="detalles_deposito"
                          value={values.detalles_deposito}
                          onBlur={(e)=>{ setFieldValue('detalles_deposito', e.target.value.toUpperCase() ) }}
                          onChange={(e)=>{ setFieldValue('detalles_deposito', e.target.value ) }}
                          isInvalid={!!errors.detalles_deposito}
                          style={{textTransform:'uppercase'}}
                        />
                        <Form.Control.Feedback type="invalid"> {<>{errors.detalles_deposito}</>} </Form.Control.Feedback>
                      </Form.Group>




                </div>
              </div>
    </>
    )
}


        </Form>
      )}
    </Formik>





        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form-elemento' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default AgregarElementoRequisicion;