import React, { useState } from 'react';
import RequestService from "../../services/request.service";
import AuthService from '../../features/auth/auth.service';
import { Button, Modal, Accordion, Card } from 'react-bootstrap';
import { Col, Row } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Dialog from '../../services/Dialogs';
import { toast } from 'react-toastify';
import { formatearNumero, formatearFechaEstandar, formatearFechaHora } from '../../services/funcionesPublicas';
import { SERVER_ } from '../../config'

// import { sumarMontosTotales, sumarMontosTotalesNoAprobados,  sumarMontosTotalesAprobados, tipoDeElementoRequisicion, formatearNumero, formatearFecha, formatearFechaHora, formatearFechaEstandar, obtenerUnidadMedida } from '../../services/funcionesPublicas'

import moment from 'moment'


const ModalComprobarDepositoSolicitante = (params:any) => {
  const user = AuthService.getCurrentUser();
  const { show, handleClose, requisicion } = params;
  const [ elementos_requisicion, setElementosRequisicion ] = useState(requisicion.elementos_requisicion);
  const dialog = new Dialog();

  const mensajes_validacion_generico = "Este campo es obligatorio";
  const schema = yup.object().shape({
    archivo: yup.mixed()
      .required('Es obligatorio subir un archivo')
      .test(
        'fileFormat',
        'Formato de archivo no soportado. Solo se permiten JPG y PNG.',
        value => value 
      ),
    uuid_elemento : yup.string()
  });

  const [envio_server, setSeEnvio] = useState(false);

  const enviarFormulario = async (registro:any, event:any) => {
    await dialog.open({
      accept: 'Si',
      cancel: 'Espera',
      dialogClass: '',
      message: '¿Estás seguro?',
      target: event.target,
      template: ''
    });
    let dialogo = await dialog.waitForUser();
    if (dialogo) {
      try {
        setSeEnvio(true);
        let res_ = await RequestService.update(`requisicion/elemento/comprobar_gasto/${registro.uuid_elemento}`, { ...registro });

        if (res_) {
          toast.success('El comprobante se subió correctamente');
          setTimeout(() => {
            cargarElementos_requisicion();
          }, 0);
        } else {
          toast.error('Problemas...');
        }
      } catch (e:any) {
        toast.error(e.response.data);
      }
    }
  };

  const handleFileChange = (event:any, setFieldValue:any) => {
    const file = event.currentTarget.files[0];
    if (file && (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'application/pdf')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFieldValue('archivo', reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setFieldValue('archivo', '');
    }
  };



  const cargarElementos_requisicion = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`requisicion/elementos_requisicion/${requisicion.uuid_requisicion}`)
      setElementosRequisicion(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }








  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Comprobar depósitos por Requisición</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {
          // JSON.stringify(elementos_requisicion)
        }

            <h6 className="">Descripción de la Requisición</h6>
            <small>{requisicion.descripcion}</small>

            <div className="row mt-2">
              <div className="col-3">
                  <h6>Folio</h6>
                  <small>{requisicion.folio}</small>
              </div>
              <div className="col-4">
                  <h6 className="mt-3-">Fecha Límite</h6>
                  <small>{moment(requisicion?.fecha_limite).utc().format('DD/MMM/YYYY').replace('.','').toUpperCase()}</small>
              </div>
              <div className="col-5">
                  <h6 className="mt-3-">Fecha de Creación</h6>
                  <small>{formatearFechaHora(requisicion?.fecha_creacion)} hrs</small>
              </div>
            </div>



          <Accordion className="mt-2" defaultActiveKey="0">
            {elementos_requisicion.map((elemento_requisicion:any, elemento_requisicion_indice:any) => (
              <React.Fragment key={elemento_requisicion_indice}>

              {
                // JSON.stringify(elemento_requisicion?.idestatus_elemento?.id)
              }


                {[99].indexOf(elemento_requisicion.idestatus_elemento.id) > -1 && (
                  <Card  className="mb-0">
                    <Card.Header>
                      <h6 className="nm">
                          <i className="fa fa-check" />{' '}
                          {elemento_requisicion.concepto}
                      </h6>
                    </Card.Header>
                    <Card.Body>
 
                          <div className="row mt-2">
                            <div className="col-4">
                                <small>Monto Solicitado</small>
                                <h6 className="nm">$ {formatearNumero(elemento_requisicion?.monto_total)}</h6>
                            </div>
                            <div className="col-4">
                                <small>Monto Depositado</small>
                                <h6 className="nm">$ {formatearNumero(elemento_requisicion?.idegreso?.monto)}</h6>
                            </div>
                            <div className="col-4">
                                <small>Tipo de requisición</small>
                                <h6 className="nm">{elemento_requisicion?.tipo.toUpperCase()}</h6>
                            </div>
                          </div>


                          <small>Estatus</small>
                          <h6 className="nm">{elemento_requisicion.idestatus_elemento.estatus}</h6>

                    </Card.Body>
                  </Card>
                  )}


                {[3,4,5,10].indexOf(elemento_requisicion.idestatus_elemento.id) > -1 && (
                  <Accordion.Item eventKey={elemento_requisicion_indice.toString()}>
                    <Accordion.Header>
                      <h6 className="nm">
                        {elemento_requisicion.documento_evidencia === null && (
                          <i className="fa fa-warning" />
                        )}
                        {' '}{elemento_requisicion.concepto}
                      </h6>
                    </Accordion.Header>


                    {
                      // JSON.stringify(elemento_requisicion.idestatus_elemento)
                    }



                    <Accordion.Body>

                    {
                      [10].indexOf(elemento_requisicion.idestatus_elemento.id) > -1 && (
                          <div className="text-center mb-2" style={{background:'#ff0000',padding:'8px 10px'}}>
                              <h6 className="nm">{elemento_requisicion.idestatus_elemento.estatus}</h6>
                              {' '}{elemento_requisicion.comentarios}
                          </div>
                        )
                    }

                          <p className="">
                            🔎 Debes comprobar este depósito {' '}
                            <b>{elemento_requisicion.con_factura ? 'CON UNA FACTURA' : ''}</b>
                          </p>

                          <small>Concepto de la requisición</small>
                          <h6 className="nm">{elemento_requisicion?.concepto}</h6>
                          <small>Monto Solicitado</small>
                          <h6 className="nm">$ {formatearNumero(elemento_requisicion?.monto_total)}</h6>
                          <small>Tipo de requisición</small>
                          <h6 className="nm">{elemento_requisicion?.tipo.toUpperCase()}</h6>
                          <small>Concepto del Depósito</small>
                          <h6 className="nm">{elemento_requisicion?.idegreso?.descripcion}</h6>
                          <small>Monto</small>
                          <h6 className="nm">$ {formatearNumero(elemento_requisicion?.idegreso?.monto)}</h6>
                          <small>Fecha del Movimiento</small>
                          <h6 className="nm">{formatearFechaEstandar(elemento_requisicion?.idegreso?.fecha_movimiento)}</h6>
                          <small>Ćomprobación de Depósito</small>
                          {
                            (elemento_requisicion?.idegreso && elemento_requisicion?.idegreso.archivo !== '') && <p className=""><button onClick={()=>{ window.open(`${SERVER_}contabilidad/archivo/${elemento_requisicion?.idegreso?.uuid}`) }} className="btn btn-sm btn-link nm">Abrir Archivo 📎</button></p>
                          }
                          {
                            (elemento_requisicion?.idegreso && elemento_requisicion?.idegreso.archivo === '') && <p className=""><small><i className="fa fa-warning" /> No existe archivo de comprobanción</small></p>
                          }

                      {
                        elemento_requisicion.documento_evidencia !== null && (
                            <>
                              <small>Ćomprobación de Evidencia del Gasto</small>
                              <p className=""><button onClick={()=>{ window.open(`${SERVER_}comprobaciones/evidencia/archivo/${elemento_requisicion?.uuid}`) }} className="btn btn-sm btn-link nm">Abrir Archivo 📎</button></p>
                            </>
                          )
                      }



                      {
                        elemento_requisicion.documento_evidencia === null && (
                        <>
                          <div className="card mt-4">
                            <div className="card-body">
                                <Formik
                                  validationSchema={schema}
                                  onSubmit={(values, e) => {
                                    enviarFormulario(values, e);
                                  }}
                                  initialValues={{
                                    archivo: '',
                                    uuid_elemento: elemento_requisicion.uuid,
                                  }}
                                >
                                  {({
                                    handleSubmit,
                                    handleChange,
                                    setFieldValue,
                                    handleBlur,
                                    values,
                                    touched,
                                    isValid,
                                    errors,
                                  }) => (
                                    <Form id={`my-form-${elemento_requisicion_indice}`} noValidate onSubmit={handleSubmit}>
                                      <div className="form-group">
                                        <label htmlFor={`archivo-${elemento_requisicion_indice}`}>
                                          Subir archivo (JPG, PNG o PDF)
                                        </label>
                                        <input
                                          id={`archivo-${elemento_requisicion_indice}`}
                                          name="archivo"
                                          type="file"
                                          className={`form-control ${
                                            touched.archivo && errors.archivo ? 'is-invalid' : ''
                                          }`}
                                          onChange={(event:any) => handleFileChange(event, setFieldValue)}
                                          accept=".jpg,.jpeg,.png,.pdf"
                                        />
                                        <ErrorMessage name="archivo" component="div" className="invalid-feedback" />
                                      </div>

                                      <Button
                                        variant="success"
                                        className="mt-2"
                                        form={`my-form-${elemento_requisicion_indice}`}
                                        type="submit"
                                      >
                                        Guardar <i className="fa fa-save" />
                                      </Button>
                                    </Form>
                                  )}
                                </Formik>
                            </div>
                          </div>
                        </>
                      )
                    }

                    </Accordion.Body>
                  </Accordion.Item>
                )}
              </React.Fragment>
            ))}
          </Accordion>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalComprobarDepositoSolicitante;
