import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'

import { rol } from '../../services/rol'
import { formatearFechaHora, formatearFechaEstandar } from '../../services/funcionesPublicas'


import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'

import Dialog from '../../services/Dialogs'



import ModalComprasFinalizar from './ModalComprasFinalizar'

import ModalRequisicionDetalles from './ModalRequisicionDetalles'


const ComprasRequisiciones = () =>{


  const dialog = new Dialog();


  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 30
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`requisiciones/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e:any){

      if (e.response?.status) {
        switch (e.response.status) {
          case 404:
            toast.error("Problemas al accedar al servidor")
            break;
          default:
            toast.error(e.response?.data)
        }
      }
      else{
        toast.error("Problemas con la busqueda")
      }
      setCargando(false)

    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([false,false,false,true,true,true,true,false])


  const todos = () =>{
    setEstatus([false,false,false,true,false,false,false,false])
  }
  const ninguno = () =>{
    setEstatus([false,false,false,false,false,false,false,false])
  }


      React.useEffect(() => {
        busqueda()
      }, []);



  const [ requisicion_seleccionada, setRequisicionSeleccionada ] = useState<any>(null)


  const [ show_ModalRequisicionDetalles, setModalRequisicionDetalles ] = useState<boolean>(false)
  const handleShow_ModalRequisicionDetalles = (requisicion:any) => {
    setRequisicionSeleccionada(requisicion)
    setModalRequisicionDetalles(true)
  }
  const handleClose_ModalRequisicionDetalles = () => {
        busqueda()
    setModalRequisicionDetalles(false)
  }






  const [ show_ModalComprasFinalizar, setModalComprasFinalizar ] = useState<boolean>(false)
  const handleShow_ModalComprasFinalizar = (requisicion:any) => {
    setRequisicionSeleccionada(requisicion)
    setModalComprasFinalizar(true)
  }
  const handleClose_ModalComprasFinalizar = () => {
        busqueda()
    setModalComprasFinalizar(false)
  }



      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Compras por Requisiciones</h4>
                    <hr/>





                    <div className="row">
                    
                        <div className="mb-2">
                            <label className="form-label">Búsqueda</label>
                            <div className="hstack gap-3">
                              <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                              <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                              <div className="vr"></div>
                              <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                            </div>
                        </div>

{/*
                        <div>
                          <Form.Check inline name="group1" type="checkbox" label="CREANDO SOLICITUD" checked={arreglo_estatus[0]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}} id={`opciones_1`} />
                          <Form.Check inline name="group1" type="checkbox" label="APROBACIÓN" checked={arreglo_estatus[1]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[1] = e.target.checked;  setEstatus(ne);}} id={`opciones_2`} />
                          <Form.Check inline name="group1" type="checkbox" label="EN ATENCIÓN" checked={arreglo_estatus[2]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[2] = e.target.checked;  setEstatus(ne);}} id={`opciones_3`} />
                          <Form.Check inline name="group1" type="checkbox" label="EN COMPRAS" checked={arreglo_estatus[3]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[3] = e.target.checked;  setEstatus(ne);}} id={`opciones_4`} />
                          <Form.Check inline name="group1" type="checkbox" label="EN TRANSFERENCIAS" checked={arreglo_estatus[4]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[4] = e.target.checked;  setEstatus(ne);}} id={`opciones_5`} />
                          <Form.Check inline name="group1" type="checkbox" label="EN ESPERA COMPROBACIÓN" checked={arreglo_estatus[5]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[5] = e.target.checked;  setEstatus(ne);}} id={`opciones_6`} />
                          <Form.Check inline name="group1" type="checkbox" label="CERRADA" checked={arreglo_estatus[6]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[6] = e.target.checked;  setEstatus(ne);}} id={`opciones_7`} />
                          <Form.Check inline name="group1" type="checkbox" label="CANCELADA" checked={arreglo_estatus[7]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[7] = e.target.checked;  setEstatus(ne);}} id={`opciones_9`} />
                        </div>*/}

                        {
                        // <div>
                        //   <Form.Check inline name="group1" type="checkbox" label="CREANDO SOLICITUD" checked={arreglo_estatus[0]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}} id={`opciones_1`} />
                        //   <Form.Check inline name="group1" type="checkbox" label="EN ATENCIÓN" checked={arreglo_estatus[1]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[1] = e.target.checked;  setEstatus(ne);}} id={`opciones_2`} />
                        //   <Form.Check inline name="group1" type="checkbox" label="EN TRANSFERENCIAS" checked={arreglo_estatus[2]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[2] = e.target.checked;  setEstatus(ne);}} id={`opciones_3`} />
                        //   <Form.Check inline name="group1" type="checkbox" label="EN ESPERA COMPROBACIÓN" checked={arreglo_estatus[3]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[3] = e.target.checked;  setEstatus(ne);}} id={`opciones_4`} />
                        //   <Form.Check inline name="group1" type="checkbox" label="CERRADA" checked={arreglo_estatus[4]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[4] = e.target.checked;  setEstatus(ne);}} id={`opciones_5`} />
                        //   <Form.Check inline name="group1" type="checkbox" label="EN COMPRAS" checked={arreglo_estatus[5]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[5] = e.target.checked;  setEstatus(ne);}} id={`opciones_6`} />
                        //   <Form.Check inline name="group1" type="checkbox" label="CANCELADA" checked={arreglo_estatus[6]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[6] = e.target.checked;  setEstatus(ne);}} id={`opciones_9`} />
                        // </div>

                      // <ul className="list-inline">
                      //   <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
                      // </ul>

                        }


                    </div>



                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col">Folio</th>
                                              <th scope="col">Proveedor</th>
                                              <th scope="col">Requisitó</th>
                                              <th scope="col">Estatus</th>
                                              <th scope="col">Creación</th>
                                              <th scope="col">Límite</th>
                                              <th scope="col">Descripción de la Requisición</th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>

                                            {


                                              resultados.map( (requisicion:any,requisicion_indice:number) =>
                                                  <tr key={ requisicion_indice } style={{fontSize:12}}>
                                                    <td className="v text-left pointer" onClick={(e:any)=>{handleShow_ModalRequisicionDetalles(requisicion)}} style={{"width":"120px"}}>
                                                            {
                                                              // JSON.stringify(requisicion)
                                                            }
                                                        <small>{requisicion.folio}</small>

                                                    </td>

                                                    <td className="v text-center" style={{"width":"20px"}}>
                                                        <p className="nm"><small><b>{requisicion.compra_proveedor?'SI':''}</b></small></p>
                                                    </td>

                                                    <td className="v text-left" style={{"width":"240px"}}>
                                                        <p className="nm"><small><b>{requisicion.nombres}</b> {requisicion.paterno} {requisicion.materno}</small></p>
                                                        <p className="nm"><small>{rol(requisicion.rol)}</small></p>
                                                    </td>
                                                    <td className={`v text-left estatus_requisicion_${requisicion.idestatus_requisicion}`} style={{"width":"140px"}}>
                                                        <p className="nm"><small><b>{requisicion.estatus}</b></small></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"180px"}}>
                                                        <p className="nm"><small><b>{formatearFechaHora(requisicion.fecha_creacion)} hrs</b></small></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"100px"}}>
                                                        <p className="nm"><small><b>{formatearFechaEstandar(requisicion.fecha_limite)} </b></small></p>
                                                    </td>
                                                    <td className="v text-left" >
                                                        <p className="nm"><small><b>{requisicion.descripcion}</b></small></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"40px"}}>
                                                      {
                                                        // [2].indexOf(requisicion.idestatus_requisicion) > -1 && (
                                                        //     <button onClick={(e:any)=>{console.log(requisicion)}} className="btn btn-sm btn-primary"><i className="fa fa-shopping-bag"/></button>
                                                        //   )
                                                      }
                                                    </td>
                                                    <td className="v text-left" style={{"width":"40px"}}>
                                                      {
                                                        [4].indexOf(requisicion.idestatus_requisicion) > -1 && (
                                                            <button onClick={(e:any)=>{handleShow_ModalComprasFinalizar(requisicion)}} className="btn btn-sm btn-primary"><i className="fa fa-shopping-cart"/></button>
                                                          )
                                                      }
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}
                    
                </div>
              </div>

            </div>



            {
              show_ModalRequisicionDetalles && (
                <ModalRequisicionDetalles show={show_ModalRequisicionDetalles} handleClose={handleClose_ModalRequisicionDetalles} requisicion={requisicion_seleccionada} />
                )

            }



            {
              show_ModalComprasFinalizar && (
                <ModalComprasFinalizar show={show_ModalComprasFinalizar} handleClose={handleClose_ModalComprasFinalizar} requisicion={requisicion_seleccionada} />
                )

            }

          </>

      );
  

}

export default ComprasRequisiciones
