import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { Routes, Route, NavLink, Outlet, useLocation } from 'react-router-dom';

import { toast } from 'react-toastify'

import { Container, Col, Row } from 'react-bootstrap';


import moment from 'moment'
import 'moment/locale/es'

import './admin.scss'

const Administracion = () =>{


  const location = useLocation();


      return (
          <>



            <Container>
              
                <h3>Administracion</h3>

                    <nav id="sidebar" className="mt-4">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <NavLink to="/administracion/tipos_equipos_criticos" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : "" } >Tipos de Equipos Críticos</NavLink>
                        </li>
                        <li className="breadcrumb-item">
                          <NavLink to="/administracion/equipos_criticos" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : "" } >Equipos Críticos</NavLink>
                        </li>
                        <li className="breadcrumb-item">
                          <NavLink to="/administracion/zonas" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : "" } >Zonas</NavLink>
                        </li>

                        <li className="breadcrumb-item">
                          <NavLink to="/administracion/subzonas" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : "" } >Rutas</NavLink>
                        </li>
                        <li className="breadcrumb-item">
                          <NavLink to="/administracion/catalogo_anexos" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : "" } >Anexos Trabajadores</NavLink>
                        </li>
                      </ol>
                    </nav>



                <hr/>
              


              <Outlet />



            </Container>

          </>

      );
  

}

export default Administracion
