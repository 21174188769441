import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal, ButtonGroup, Form } from 'react-bootstrap';

import { toast } from 'react-toastify'

import moment from 'moment'

import SignatureCanvas from 'react-signature-canvas'

import { diferenciaHoras, obtenerDiaSemana, ordenarPorTipo, formatearNumero, labelUnidadMedida, calcularTamanoEnKB, calificaciones } from '../../services/funcionesPublicas'

import Dialog from '../../services/Dialogs'

// import { sonValoresIguales, obtenerDiaSemana, , tipoActividadLabel, ,  } from '../../services/funcionesPublicas'

import { SERVER_ } from '../../config'

import '../Operarios/canvas.scss'


const ModalDetalleActividad = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, tarea } = params
 


  const [ detalles_visita, setDetalles_visita ] = useState<any>(null)
  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const cargarDetalles_visita = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`ficha_servicio/${tarea.uuid_servicio}`)
      setDetalles_visita(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarDetalles_visita()
  }, []);






const dialog = new Dialog();

const autorizarCliente = async (event:any, uuid_servicio:string) =>{


  // console.log(uuid_servicio)
  
            let size_kb_min = 3.2
              // console.log(calcularTamanoEnKB(canvas.toDataURL()))

            if( calcularTamanoEnKB(canvas.toDataURL()) < size_kb_min ){

              toast.error('Debes firmar para poder autorizar',{ icon : '🚨' })
              return 

            }

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: `<p>El Cliente podrá ver todo el reporte visual</p>`
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `servicio/autorizar_ver_cliente/${uuid_servicio}` , { firma_responsable : canvas.toDataURL() })

        if(res_){
          toast.success('Ahora el cliente podrá ver el Reporte Visual')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){

          if (e.response?.status) {
            switch (e.response.status) {
              case 404:
                toast.error("Debes escribir algo")
                break;
              default:
                toast.error(e.response?.data)
            }
          }
          else{
            toast.error("Problemas con la busqueda")
          }
          setSeEnvio(false)

        }
        
      }
     
}



const mandarComponerReporte = async (event:any, uuid_servicio:string) =>{
  // console.log(uuid_servicio)

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: `<p>Quién atendió el servicio podrá hacer cambios</p>`
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `servicio/enviar_componer/${uuid_servicio}` , { })

        if(res_){
          toast.success('Ahora quién atendió puede hacer cambios')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){

          if (e.response?.status) {
            switch (e.response.status) {
              case 404:
                toast.error("Debes escribir algo")
                break;
              default:
                toast.error(e.response?.data)
            }
          }
          else{
            toast.error("Problemas con la busqueda")
          }
          setSeEnvio(false)

        }
        
      }
     
}



  const [ canvas, sigCanvas ] = useState<any>({})
  const [ tiene_algo, setTieneAlgo ] = useState(false)

  const borrar = () =>{
    canvas.clear()
    setTieneAlgo(false)
  }




  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Detalles de la Actividad </Modal.Title>
        </Modal.Header>


        {
          ["ROLE_ADMIN","ROLE_RESPONSABLE_OPERACION","ROLE_RESPONSABLE_TECNICOS","ROLE_RESPONSABLE_LIMPIEZA"].indexOf(user.rol) > -1 && (
              <React.Fragment>
              {
                tarea.calificacion_boutique && (<div className={`calificacion_servicio_${tarea.calificacion_boutique}`}><p className="nm"><small>Calificación del Servicio:</small></p><h3 className="text-center nm">{calificaciones[tarea.calificacion_boutique]}</h3></div>)
              }
              </React.Fragment>
            )
        }


        <Modal.Body>


        {
          envio_server && (<p>Cargando detalles del servicio...</p>)
        }



        {
          (!envio_server && detalles_visita) && (<React.Fragment>
            

          <div className="row">
            <div className="col-sm-6">
              <small>Folio</small>
              <h5>
                {detalles_visita.folio}  {' '}
                {
                  [7].indexOf(detalles_visita.idestatus_atencion.id) > -1 && (
                      <React.Fragment>
                        {
                          detalles_visita !== null && (
                              <React.Fragment>
                                {
                                  // detalles_visita.idpresupuesto && 
                                  (
                                      <React.Fragment>
                                        {
                                          true && 
                                          (<i className="fa fa-print pointer" onClick={(e:any)=>{window.open(`${SERVER_}reporte/hoja_servicio/${detalles_visita.uuid}`)}} />)
                                        }       
                                      </React.Fragment>
                                    )
                                }

                              </React.Fragment>

                            )
                        }

                        {
                          // JSON.stringify(detalles_visita.idpresupuesto)
                        }

                      </React.Fragment>
                    )
                }
              </h5>



            </div>
            <div className="col-sm-6">
              <small>Fecha</small>
              <h6 className="nm"><small>{obtenerDiaSemana(detalles_visita.fecha_programada).toUpperCase()}</small>  {moment(detalles_visita.fecha_programada).format('DD/MMM/YYYY').toUpperCase().replace('.','')}</h6>
              <small></small>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <small>Boutique</small>
              <h5>{detalles_visita.idboutique.nombre_boutique}</h5>
              <p>{detalles_visita.idboutique.direccion}</p>
            </div>
          </div>

          <div className="row mb-2">
            <div className={`col-sm-6 estatus_atencion_${detalles_visita.idestatus_atencion.id}`}>
              <small>Estatus</small>
              <h5>{detalles_visita.idestatus_atencion.estatus}</h5>
            </div>
            <div className={`col-sm-6 tipo_orden_${detalles_visita.idtipo_orden.id}`}>
              <small>Tipo de Servicio</small>
              <h5>{detalles_visita.idtipo_orden.tipo_orden}</h5>
            </div>
          </div>


              <small>Atendió</small>
              <h5>{detalles_visita.idtecnico.nombres} {detalles_visita.idtecnico.paterno} {detalles_visita.idtecnico.materno}</h5>



              <small>Indicaciones para la Actividad</small>
              <h5>{detalles_visita.indicaciones||'Actividades listadas'}</h5>



          {
            [3,4,7].indexOf(detalles_visita.idestatus_atencion.id) > -1 && (
                    <React.Fragment>
                        <small>Detalles de la Atención</small>
                        <h6>{detalles_visita.detalles_atencion||'Sin detalles'}</h6>
                    </React.Fragment>
              )
          }


          {
            [99,6].indexOf(detalles_visita.idestatus_atencion.id) > -1 && (
                    <React.Fragment>
                        <small>Motivos</small>
                        <h6>{detalles_visita.detalles_atencion||'Sin detalles'}</h6>
                    </React.Fragment>
              )
          }


 



                          {
                            ([4,7].indexOf(detalles_visita.idestatus_atencion.id) > -1 && detalles_visita ) && (
                                <React.Fragment>

                                  {
                                    detalles_visita.actividades_reporte && (
                                        <React.Fragment>

                                          <small>Actividades Realizadas</small>


                                          {
                                            detalles_visita.actividades_reporte.length === 0 && (
                                                <p className="nm"><b>Actividades detalladas en reporte</b></p>
                                              )
                                          }

                                          {
                                            detalles_visita.actividades_reporte.length > 0 && (
                                                <ol>
                                                  {
                                                    detalles_visita.actividades_reporte.map((actividad:any,indice_actividad:number)=><li key={indice_actividad}>{actividad}</li>)
                                                  }
                                                </ol>
                                              )
                                          }

                                        </React.Fragment>
                                      )
                                  }
                                  


                                </React.Fragment>
                                  )
                          }



                          {
                            ([7].indexOf(detalles_visita.idestatus_atencion.id) > -1 && detalles_visita ) && (
                                <React.Fragment>
                                  





                                        {
                                          detalles_visita.idpresupuesto && (
                                              <React.Fragment>

                                                
                                                
                                                {
                                                  detalles_visita.idpresupuesto.materiales_actividades.length > 0 && (
                                                        <small>Materiales usados en el servicio</small>
                                                    )
                                                }
                                                   {
                                                    detalles_visita.idpresupuesto.materiales_actividades.length > 0 && (
                                                      <table className="table table-sm mt-2" style={{fontSize:12}}>
                                                        <thead>
                                                          <tr>
                                                            <th scope="col">Descripción</th>
                                                            <th scope="col">Unidad</th>
                                                            <th scope="col">Cantidad</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                          [...detalles_visita.idpresupuesto.materiales_actividades].sort(ordenarPorTipo).map((material:any,index:number)=>


                                                          <tr key={ Math.random().toString(36).substr(2, 9) }> 
                                                            <td>
                                                              <p className="nm"><small>{material.descripcion}</small></p>
                                                            </td>
                                                            <td className="v" style={{width:120}}>
                                                              <p className="nm"><small>{ material.idunidad_medida.unidad_medida}</small></p>
                                                            </td>
                                                            <td className="v" style={{width:80}}>
                                                              <p className="nm text-right">{formatearNumero(parseFloat(material.cantidad)||0)}</p>
                                                            </td>  
                                                          </tr>

                                                            )
                                                        }


                                                        </tbody>
                                                      </table>
                                                      )
                                                  }

                                              </React.Fragment>
                                            )
                                        }








                                          {
                                            ["ROLE_ADMIN","ROLE_RESPONSABLE_OPERACION","ROLE_RESPONSABLE_TECNICOS","ROLE_RESPONSABLE_LIMPIEZA"].indexOf(user.rol) > -1 && (
                                                <React.Fragment>



                                                        {
                                                          detalles_visita.idreporte_evidencia_visual && (
                                                              <React.Fragment>
                                                                  {
                                                                    // JSON.stringify(detalles_visita.idreporte_evidencia_visual.anexos)
                                                                  }

                                                                  <div className="container" style={{ maxHeight: '400px', overflowY: 'auto' }}>

                                                                      <div className="row">
                                                                        {detalles_visita.idreporte_evidencia_visual.anexos.map((foto:any,indice_foto:number) => (
                                                                          <div key={indice_foto} className="col-md-4">
                                                                            <div className="gallery-item">
                                                                              <img onClick={(e:any)=>{ window.open(`${SERVER_}reporte_evidencias_visuales/preview_anexo/${foto.uuid}/${Math.random().toString(36).substr(2, 9)}`) }} src={`${SERVER_}reporte_evidencias_visuales/preview_anexo/${foto.uuid}/${Math.random().toString(36).substr(2, 9)}`} title={foto.descripcion} className="img-fluid" />
                                                                              {
                                                                                // JSON.stringify(foto)
                                                                              }
                                                                            </div>
                                                                          </div>
                                                                        ))}
                                                                      </div>

                                                                  </div>
                                                              </React.Fragment>
                                                            )
                                                        }


                                                </React.Fragment>
                                              )
                                          }







                                          {
                                            ["ROLE_TECNICO","ROLE_LIMPIEZA"].indexOf(user.rol) > -1 && (
                                                <React.Fragment>


                                                        {
                                                          detalles_visita.idreporte_evidencia_visual && (
                                                              <React.Fragment>
                                                                  {
                                                                    // JSON.stringify(detalles_visita.idreporte_evidencia_visual.anexos)
                                                                  }

                                                                  <div className="container" style={{ maxHeight: '400px', overflowY: 'auto' }}>

                                                                      <div className="row">
                                                                        {detalles_visita.idreporte_evidencia_visual.anexos.map((foto:any,indice_foto:number) => (
                                                                          <div key={indice_foto} className="col-md-4">
                                                                            <div className="gallery-item">
                                                                              <img onClick={(e:any)=>{ window.open(`${SERVER_}reporte_evidencias_visuales/preview_anexo/${foto.uuid}/${Math.random().toString(36).substr(2, 9)}`) }} src={`${SERVER_}reporte_evidencias_visuales/preview_anexo/${foto.uuid}/${Math.random().toString(36).substr(2, 9)}`} title={foto.descripcion} className="img-fluid" />
                                                                              {
                                                                                // JSON.stringify(foto)
                                                                              }
                                                                            </div>
                                                                          </div>
                                                                        ))}
                                                                      </div>

                                                                  </div>
                                                              </React.Fragment>
                                                            )
                                                        }


                                                </React.Fragment>
                                              )
                                          }








                                          {
                                            (["ROLE_BOUTIQUE","ROLE_CLIENTE"].indexOf(user.rol) > -1 && detalles_visita.firma_responsable ) && (
                                                <React.Fragment>


                                                        {
                                                          detalles_visita.idreporte_evidencia_visual && (
                                                              <React.Fragment>
                                                                  {
                                                                    // JSON.stringify(detalles_visita.idreporte_evidencia_visual.anexos)
                                                                  }

                                                                    {
                                                                      detalles_visita.firma_responsable && (
                                                                          <React.Fragment>

                                                                              <div className="container" style={{ maxHeight: '400px', overflowY: 'auto' }}>

                                                                                  <div className="row">
                                                                                    {detalles_visita.idreporte_evidencia_visual.anexos.map((foto:any,indice_foto:number) => (
                                                                                      <div key={indice_foto} className="col-md-4">
                                                                                        <div className="gallery-item">
                                                                                          <img onClick={(e:any)=>{ window.open(`${SERVER_}reporte_evidencias_visuales/preview_anexo/${foto.uuid}/${Math.random().toString(36).substr(2, 9)}`) }} src={`${SERVER_}reporte_evidencias_visuales/preview_anexo/${foto.uuid}/${Math.random().toString(36).substr(2, 9)}`} title={foto.descripcion} className="img-fluid" />
                                                                                          {
                                                                                            // JSON.stringify(foto)
                                                                                          }
                                                                                        </div>
                                                                                      </div>
                                                                                    ))}
                                                                                  </div>

                                                                              </div>


                                                                          </React.Fragment>
                                                                        )
                                                                    }



                                                              </React.Fragment>
                                                            )
                                                        }


                                                </React.Fragment>

                                              )
                                          }




                                </React.Fragment>
                              )
                          }







                          {
                            ["ROLE_ADMIN","ROLE_RESPONSABLE_OPERACION","ROLE_RESPONSABLE_TECNICOS","ROLE_RESPONSABLE_LIMPIEZA"].indexOf(user.rol) > -1 && (
                                <React.Fragment>

                                {
                                  (detalles_visita.autoriza_finalizar === null && detalles_visita.idestatus_atencion.id === 7 ) && (

                                      <div className="btn-group d-flex mt-2 mb-2 w-100" role="group" aria-label="..."> 
                                        <button onClick={(e:any)=>{mandarComponerReporte(e,detalles_visita.uuid)}} type="button" className="btn btn-danger w-100"><small>Regresar a corregir</small></button> 
                                      </div>


                                    )
                                }

                                </React.Fragment>
                              )
                          }









                          {
                            // JSON.stringify(detalles_visita.autoriza_finalizar)
                          }




                          {
                            (["ROLE_ADMIN","ROLE_RESPONSABLE_OPERACION","ROLE_RESPONSABLE_TECNICOS","ROLE_RESPONSABLE_LIMPIEZA"].indexOf(user.rol) > -1 && [7].indexOf(detalles_visita.idestatus_atencion.id) > -1 ) && (
                                <React.Fragment>
                                  {
                                    detalles_visita.firma_responsable === null && (
                                        <React.Fragment>
                                       
                                            <div className="alert alert-warning" role="alert">
                                                <i className="fa fa-warning" /> Recuerda que debes firmar la hoja de servicio para que el cliente pueda ver el reporte del Servicio
                                            </div>

                                            <p className="text-center">
                                                        <Form.Label>Firma del Responsable</Form.Label>
                                              <SignatureCanvas penColor='#000000' canvasProps={{width: 300, height: 300, className: 'sigCanvas'}} ref={(ref:any) => { sigCanvas(ref) }} onEnd={(a:any)=>{setTieneAlgo(true); }} />
                                            </p>

                                            <p>
                                              <small onClick={borrar} className="pointer" style={{color:'red'}}>Borrar <i className="fa fa-eraser"/></small>
                                            </p>

                                            <button onClick={(e:any)=>{autorizarCliente(e,detalles_visita.uuid)}} type="button" className="btn btn-success w-100"><small>Mostrar al Cliente</small></button>        

                                        </React.Fragment>
                                      )
                                  }




                                  {
                                    detalles_visita.firma_responsable !== null && (
                                        <React.Fragment>
                                       
                                            <div className="alert alert-primary" role="alert">
                                                <i className="fa fa-check-circle"/> El cliente puede ver el reporte de evidencias
                                            </div>

                                        </React.Fragment>
                                      )
                                  }






                                </React.Fragment>
                              )

                          }














              {
                detalles_visita.fecha_inicio_servicio && (
                    <React.Fragment>

                      <div className="row mt-3">
                        <div className={`col-sm-6`}>
                          <small>Fecha de Inicio del Servicio</small>
                          <h5>{moment(detalles_visita.fecha_inicio_servicio).format('YYYY-MM-DD HH:mm')} hrs</h5>
                        </div>
                        <div className="col-sm-6">
                          {
                            detalles_visita.fecha_fin_servicio && (
                                <React.Fragment>
                                  <small>Fecha de Fin del Servicio</small>
                                  <h5>{moment(detalles_visita.fecha_fin_servicio).format('YYYY-MM-DD HH:mm')} hrs</h5>
                                </React.Fragment>
                              )
                          }
                        </div>
                      </div>

                    </React.Fragment>
                  )
              }




              {
                detalles_visita.fecha_fin_servicio && (
                    <React.Fragment>
                        <small>Tiempo entre reporte de entrada y salida</small>
                        <h5>{diferenciaHoras(detalles_visita.fecha_inicio_servicio, detalles_visita.fecha_fin_servicio)}</h5>
                    </React.Fragment>
                  )
              }



          </React.Fragment>)
        }





        </Modal.Body>

      </Modal>
    </>
  );
}


export default ModalDetalleActividad;