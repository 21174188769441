import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'

import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'


import ModalNuevaRequisicion from './ModalNuevaRequisicion'
import ModalEditarRequisicion from './ModalEditarRequisicion'

import ModalComprobarDepositoSolicitante from './ModalComprobarDepositoSolicitante'


import ModalElementoCargaComprobantes from './ModalElementoCargaComprobantes'


import ModalRequisicionDetalles from './ModalRequisicionDetalles'


import Dialog from '../../services/Dialogs'

import { formatearFechaEstandar } from '../../services/funcionesPublicas'

import './requisiciones.scss'

const MisRequisiciones = () =>{

  const dialog = new Dialog();

  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 20
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`mis_requisiciones/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e:any){

      if (e.response?.status) {
        switch (e.response.status) {
          case 404:
            toast.error("Debes escribir algo")
            break;
          default:
            toast.error(e.response?.data)
        }
      }
      else{
        toast.error("Problemas con la busqueda")
      }
      setCargando(false)

    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true,true,true,true,true,true,true,true,true])


  const todos = () =>{
    setEstatus([true,true,true,true,true,true,true,true,true])
  }
  const ninguno = () =>{
    setEstatus([false,false,false,false,false,false,false,false,false])
  }



      React.useEffect(() => {
        busqueda()
      }, []);



  const [ show_ModalNuevaRequisicion, setModalNuevaRequisicion ] = useState<boolean>(false)
  const handleShow_ModalNuevaRequisicion = () => {
    setModalNuevaRequisicion(true)
  }
  const handleClose_ModalNuevaRequisicion = () => {
        busqueda()
    setModalNuevaRequisicion(false)
  }


  const [ show_ModalNuevaRequisicionProveedor, setModalNuevaRequisicionProveedor ] = useState<boolean>(false)
  const handleShow_ModalNuevaRequisicionProveedor = () => {
    setModalNuevaRequisicionProveedor(true)
  }
  const handleClose_ModalNuevaRequisicionProveedor = () => {
        busqueda()
    setModalNuevaRequisicionProveedor(false)
  }




  const [ requisicion_seleccionada, setRequisicionSeleccionada ] = useState<any>(null)
  const [ show_ModalEditarRequisicion, setModalEditarRequisicion ] = useState<boolean>(false)
  const handleShow_ModalEditarRequisicion = (requisicion:any) => {
    setRequisicionSeleccionada(requisicion)
    setModalEditarRequisicion(true)
  }
  const handleClose_ModalEditarRequisicion = () => {
        busqueda()
    setModalEditarRequisicion(false)
  }

  const [ show_ModalEditarRequisicionProveedor, setModalEditarRequisicionProveedor ] = useState<boolean>(false)
  const handleShow_ModalEditarRequisicionProveedor = (requisicion:any) => {
    setRequisicionSeleccionada(requisicion)
    setModalEditarRequisicionProveedor(true)
  }
  const handleClose_ModalEditarRequisicionProveedor = () => {
        busqueda()
    setModalEditarRequisicionProveedor(false)
  }



const [ envio_server, setSeEnvio ] = useState<boolean>(false)
const cancelarRequisicion = async (event:any,requisicion:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `requisiciones/cancelar/${requisicion.uuid_requisicion}` , { })

        if(res_){
          toast.warning('Le Requisción fue marcada como cancelada correctamente')
          setTimeout(()=>{
            busqueda()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          if (e.response?.status) {
            switch (e.response.status) {
              default:
                toast.error(e.response?.data)
            }
          }
          else{
            toast.error("Problemas con la busqueda")
          }
          setSeEnvio(false)

        }
        
      }
     
}



const aprobarRequisicion = async (event:any,requisicion:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: `
          <p class="nm"><small>La Requisición pasará a verificación y aprobación</small></p>
          <p class="nm"><small>Tendrás que esperar a que se resuelva</small></p>
        `
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `requisiciones/aprobar/${requisicion.uuid_requisicion}` , { })

        if(res_){
          toast.success('Le Requisción se marco para verificación y aprobación')
          setTimeout(()=>{
            busqueda()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          if (e.response?.status) {
            switch (e.response.status) {
              default:
                toast.error(e.response?.data)
            }
          }
          else{
            toast.error("Problemas con la busqueda")
          }
          setSeEnvio(false)

        }
        
      }
     
}



  const [ show_ModalComprobarDepositoSolicitante, setModalComprobarDepositoSolicitante ] = useState<boolean>(false)
  const handleShow_ModalComprobarDepositoSolicitante = (requisicion:any) => {
    setRequisicionSeleccionada(requisicion)
    setModalComprobarDepositoSolicitante(true)
  }
  const handleClose_ModalComprobarDepositoSolicitante = () => {
        busqueda()
    setModalComprobarDepositoSolicitante(false)
  }




  const [ show_ModalRequisicionDetalles, setModalRequisicionDetalles ] = useState<boolean>(false)
  const handleShow_ModalRequisicionDetalles = (requisicion:any) => {
    setRequisicionSeleccionada(requisicion)
    setModalRequisicionDetalles(true)
  }
  const handleClose_ModalRequisicionDetalles = () => {
        busqueda()
    setModalRequisicionDetalles(false)
  }


  const [ show_ModalElementoCargaComprobantes, setModalElementoCargaComprobantes ] = useState<boolean>(false)
  const handleShow_ModalElementoCargaComprobantes = (requisicion:any) => {
    setRequisicionSeleccionada(requisicion)
    setModalElementoCargaComprobantes(true)
  }
  const handleClose_ModalElementoCargaComprobantes = () => {
        busqueda()
    setModalElementoCargaComprobantes(false)
  }



      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Mis Requisiciones</h4>
                    <hr/>

                          <div className="row">
                          
                              <div className="mb-2">
                                  <label className="form-label">Búsqueda</label>
                                  <div className="hstack gap-3">
                                    <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                                    <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                                    <div className="vr"></div>
                                    <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                                  </div>
                              </div>
                              
                        <div>
                          <Form.Check inline name="group1" type="checkbox" label="CREANDO SOLICITUD" checked={arreglo_estatus[0]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}} id={`opciones_1`} />
                          <Form.Check inline name="group1" type="checkbox" label="APROBACIÓN" checked={arreglo_estatus[1]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[1] = e.target.checked;  setEstatus(ne);}} id={`opciones_2`} />
                          <Form.Check inline name="group1" type="checkbox" label="EN ATENCIÓN" checked={arreglo_estatus[2]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[2] = e.target.checked;  setEstatus(ne);}} id={`opciones_3`} />
                          <Form.Check inline name="group1" type="checkbox" label="EN COMPRAS" checked={arreglo_estatus[3]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[3] = e.target.checked;  setEstatus(ne);}} id={`opciones_4`} />
                          <Form.Check inline name="group1" type="checkbox" label="EN TRANSFERENCIAS" checked={arreglo_estatus[4]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[4] = e.target.checked;  setEstatus(ne);}} id={`opciones_5`} />
                          <Form.Check inline name="group1" type="checkbox" label="EN ESPERA COMPROBACIÓN" checked={arreglo_estatus[5]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[5] = e.target.checked;  setEstatus(ne);}} id={`opciones_6`} />
                          <Form.Check inline name="group1" type="checkbox" label="CERRADA" checked={arreglo_estatus[6]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[6] = e.target.checked;  setEstatus(ne);}} id={`opciones_7`} />
                          <Form.Check inline name="group1" type="checkbox" label="PENDIENTE" checked={arreglo_estatus[7]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[7] = e.target.checked;  setEstatus(ne);}} id={`opciones_8`} />
                          <Form.Check inline name="group1" type="checkbox" label="CANCELADA" checked={arreglo_estatus[8]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[8] = e.target.checked;  setEstatus(ne);}} id={`opciones_9`} />
                        </div>
                              
                            <ul className="list-inline">
                              <li className="list-inline-item"><small className="pointer" onClick={handleShow_ModalNuevaRequisicion}>Nueva Requisición</small></li>
                              <li className="list-inline-item"><small className="pointer" onClick={handleShow_ModalNuevaRequisicionProveedor}>Nueva Compra con Proveedor</small></li>
                              <li className="list-inline-item"><small className="pointer" onClick={todos}>Todos</small> / <small className="pointer" onClick={ninguno}>Ninguno</small></li>
                            </ul>

                          </div>







                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr style={{fontSize:12}}>
                                              <th scope="col">Folio</th>
                                              <th scope="col">Creación</th>
                                              <th scope="col">Límite</th>
                                              <th scope="col">Estatus</th>
                                              <th scope="col">Proveedor</th>
                                              <th scope="col">Compras</th>
                                              <th scope="col">Mía</th>
                                              <th scope="col">Detalles</th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>

                                            {


                                              resultados.map( (requisicion:any,requisicion_indice:number) =>
                                                  <tr key={ requisicion_indice } style={{fontSize:12}}>
                                                    <td className="v text-left pointer" onClick={(e:any)=>{handleShow_ModalRequisicionDetalles(requisicion)}} style={{"width":"120px"}}>
                                                            {
                                                              // JSON.stringify(requisicion)
                                                            }
                                                        <small>{requisicion.folio}</small>

                                                    </td>
                                                    <td className="v text-left" style={{"width":"180px"}}>
                                                        <p className="nm"><small><b>{moment(requisicion.fecha_creacion).format('DD/MMMM/YYYY HH:mm').toUpperCase()} hrs</b></small></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"120px"}}>
                                                        <p className="nm"><small><b>{formatearFechaEstandar(requisicion.fecha_limite)}</b></small></p>
                                                    </td>
                                                    <td className={`v text-left estatus_requisicion_${requisicion.idestatus_requisicion}`} style={{"width":"180px"}}>
                                                        <p className="nm"><small><b>{requisicion.estatus}</b></small></p>
                                                    </td>
                                                    <td className="v text-center" style={{"width":"20px"}}>
                                                        <p className="nm"><small><b>{requisicion.compra_proveedor?'SI':''}</b></small></p>
                                                    </td>
                                                    <td className="v text-center" style={{"width":"20px"}}>
                                                        <p className="nm"><small><b>{requisicion.para_compras?'SI':''}</b></small></p>
                                                    </td>
                                                    <td className="v text-center" style={{"width":"20px"}}>
                                                        <p className="nm"><small><b>{requisicion.es_mia?'SI':''}</b></small></p>
                                                      {
                                                        // JSON.stringify(requisicion.es_mia)
                                                      }
                                                      {
                                                        // JSON.stringify(requisicion.usuario_solicita)
                                                      }
                                                    </td>
                                                    <td className="v text-left" >
                                                        {
                                                          requisicion.usuario_solicita && (
                                                              <>
                                                                <b>⚠️ REQUISICIÓN PARA </b>{requisicion.usuario_solicita.nombres} {requisicion.usuario_solicita.paterno} {requisicion.usuario_solicita.materno}
                                                              </>
                                                            )
                                                        }
                                                        <p className="nm"><b>{requisicion?.idproveedor?.proveedor}</b></p>
                                                        <p className="nm"><small>{requisicion.descripcion}</small></p>
                                                    </td>

                                                    <td style={{"width":"40px"}}>
                                                      {
                                                        [1].indexOf(requisicion.idestatus_requisicion) > -1 && (
                                                            <button type="button" onClick={(e:any)=>{ requisicion.compra_proveedor ? handleShow_ModalEditarRequisicionProveedor(requisicion) : handleShow_ModalEditarRequisicion(requisicion)}} className="btn btn-sm btn-warning"><i className="fa fa-edit"/></button>
                                                          )
                                                      }

                                                      {
                                                        [6].indexOf(requisicion.idestatus_requisicion) > -1 && (
                                                            <button onClick={(e:any)=>{handleShow_ModalComprobarDepositoSolicitante(requisicion)}} className="btn btn-sm btn-primary">Comprobación</button>
                                                          )
                                                      }


                                                    </td>
                                                    <td style={{"width":"40px"}}>
                                                      {
                                                        [1,2,3].indexOf(requisicion.idestatus_requisicion) > -1 && (
                                                            <button onClick={(e:any)=>{handleShow_ModalElementoCargaComprobantes(requisicion)}} className="btn btn-sm btn-mantenimiento"><i className="fa fa-upload"/></button>
                                                          )
                                                      }
                                                    </td>
                                                    <td style={{"width":"40px"}}>
                                                      {
                                                        [1].indexOf(requisicion.idestatus_requisicion) > -1 && (
                                                            <button type="button" onClick={(e:any)=>{aprobarRequisicion(e,requisicion)}} className="btn btn-sm btn-secondary"><i className="fa fa-share"/></button>
                                                          )
                                                      }
                                                    </td>
                                                    <td style={{"width":"40px"}}>
                                                      {
                                                        [1].indexOf(requisicion.idestatus_requisicion) > -1 && (
                                                            <button type="button" onClick={(e:any)=>{cancelarRequisicion(e,requisicion)}} className="btn btn-sm btn-danger"><i className="fa fa-trash-o"/></button>
                                                          )
                                                      }
                                                    </td>

                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}


                    
                </div>
              </div>

            </div>



            {
              show_ModalNuevaRequisicion && (
                <ModalNuevaRequisicion show={show_ModalNuevaRequisicion} handleClose={handleClose_ModalNuevaRequisicion} proveedor={false} />
                )

            }


            {
              show_ModalNuevaRequisicionProveedor && (
                <ModalNuevaRequisicion show={show_ModalNuevaRequisicionProveedor} handleClose={handleClose_ModalNuevaRequisicionProveedor} proveedor={true} />
                )

            }


            {
              show_ModalEditarRequisicion && (
                <ModalEditarRequisicion show={show_ModalEditarRequisicion} handleClose={handleClose_ModalEditarRequisicion} requisicion={requisicion_seleccionada} proveedor={false} />
                )

            }

            {
              show_ModalEditarRequisicionProveedor && (
                <ModalEditarRequisicion show={show_ModalEditarRequisicionProveedor} handleClose={handleClose_ModalEditarRequisicionProveedor} requisicion={requisicion_seleccionada} proveedor={true} />
                )

            }



            {
              show_ModalComprobarDepositoSolicitante && (
                <ModalComprobarDepositoSolicitante show={show_ModalComprobarDepositoSolicitante} handleClose={handleClose_ModalComprobarDepositoSolicitante} requisicion={requisicion_seleccionada} />
                )

            }




            {
              show_ModalElementoCargaComprobantes && (
                <ModalElementoCargaComprobantes show={show_ModalElementoCargaComprobantes} handleClose={handleClose_ModalElementoCargaComprobantes}  requisicion={requisicion_seleccionada}  />
                )

            }




            {
              show_ModalRequisicionDetalles && (
                <ModalRequisicionDetalles show={show_ModalRequisicionDetalles} handleClose={handleClose_ModalRequisicionDetalles} requisicion={requisicion_seleccionada} />
                )

            }





          </>

      );
  

}

export default MisRequisiciones
