import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'



import FullCalendar from '@fullcalendar/react'
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list';

// import ModalDetalleEvento from './ModalDetalleEvento'

import ModalDetalleActividad from '../Operacion/ModalDetalleActividad'

const CalendarioFull = () =>{


    const [bookings, setBookings] = useState<any>([ ])




    const [ mis_tareas, setMis_tareas ] = useState<any>([])
    const cargarMis_tareas = async()=>{
      try{
        
        let response : any = await RequestService.getEndPoint(`tecnicos/tareas_periodo`)
        // setMis_tareas(response.data)
        setBookings(response.data)


      }
      catch(e){
        toast.error('Problemas al intentar cargar')
      }
    }


    React.useEffect(() => {
      cargarMis_tareas()
    }, []);




    // const [ show_ModalDetalleEvento, setModalDetalleEvento ] = useState<boolean>(false)
    // const [ evento_seleccionado, setEventoSeleccionado ] = useState<any>({})
    // const handleShow_ModalDetalleEvento = (info:any) => {
    //   setEventoSeleccionado(info.event)
    //   setModalDetalleEvento(true)
    // }
    // const handleClose_ModalDetalleEvento = () => {
    //   setModalDetalleEvento(false)
    // }




  const [ show_ModalDetalleActividad, setModalDetalleActividad ] = useState<boolean>(false)
    const [ tarea_seleccionada, setTareaSeleccionada ] = useState<any>(null)
  const handleShow_ModalDetalleActividad = (tarea:any) => {
    console.log(tarea.event.extendedProps.metadata.uuid)
    setTareaSeleccionada({ uuid_servicio : tarea.event.extendedProps.metadata.uuid })
    setModalDetalleActividad(true)
  }
  const handleClose_ModalDetalleActividad = () => {
    setModalDetalleActividad(false)
  }



// onClick={(e:any)=>{handleShow_ModalDetalleActividad(visita)}}

      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Calendario de Operación del Periodo Actual</h4>
                    <hr/>



                    <FullCalendar
                      plugins={[dayGridPlugin, interactionPlugin, listPlugin ]}
                      initialView="dayGridMonth"
                      
                      eventClick={handleShow_ModalDetalleActividad}
                      events={bookings}
                    />


                    {
                                        // <Calendar bookings={bookings} />
                    }



                    
                </div>
              </div>

            </div>




  {
    show_ModalDetalleActividad && (
      <ModalDetalleActividad show={show_ModalDetalleActividad} handleClose={handleClose_ModalDetalleActividad} tarea={tarea_seleccionada} />
      )

  }



          </>

      );
  

}

export default CalendarioFull
