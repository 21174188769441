import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { rol } from '../../services/rol'

import { sumarMontosTotales, sumarMontosTotalesNoAprobados,  sumarMontosTotalesAprobados, tipoDeElementoRequisicion, formatearNumero, formatearFecha, formatearFechaHora, formatearFechaEstandar, obtenerUnidadMedida } from '../../services/funcionesPublicas'

import moment from 'moment'

import { SERVER_ } from '../../config'


const ModalRequisicionDetalles = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, requisicion } = params


  const [ requisicion_detalle, setRequisicion_detalle ] = useState<any>([])
  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const cargarRequisicion_detalle = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`requisicion/detalles/${requisicion.uuid_requisicion}`)
      setRequisicion_detalle(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarRequisicion_detalle()
    // cargarTipo_unidad_medida()
  }, []);





  const proveedor= requisicion.compra_proveedor


  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Detalles de la Requisición</Modal.Title>
        </Modal.Header>

              <div className={`text-center estatus_requisicion_${requisicion_detalle?.idestatus_requisicion?.id}`} style={{padding:'10px 0px',marginBottom:0}}>
                {
                  // JSON.stringify(requisicion_detalle.idestatus_requisicion)
                }
                      <h6 className="">Estatus</h6>
                      <small>{requisicion_detalle?.idestatus_requisicion?.estatus}</small>

              </div>


            {
              requisicion_detalle.para_compras && (
                  <div className="text-center pasara_por_compras" style={{marginTop:0}}>
                  🛍️ Esta requisición será atendida por el Departamento de Compras
                  </div>
                )
            }


        <Modal.Body>
          {
            // JSON.stringify(requisicion_detalle.idusuario_crea)
          }


            <h6 className="">Descripción de la Requisición</h6>
            <small>{requisicion_detalle.descripcion}</small>

            <div className="row mt-2">
              <div className="col-4">
                  <h6>Folio</h6>
                  <small>{requisicion_detalle.folio}</small>
              </div>
              <div className="col-4">
                  <h6 className="mt-3-">Fecha Límite</h6>
                  <small>{moment(requisicion_detalle?.fecha_limite).utc().format('DD/MMM/YYYY').replace('.','').toUpperCase()}</small>
              </div>
              <div className="col-4">
                  <h6 className="mt-3-">Fecha de Creación</h6>
                  <small>{formatearFechaHora(requisicion_detalle?.fecha_creacion)} hrs</small>
              </div>
            </div>


            {
              // JSON.stringify(requisicion_detalle.para_compras)
            }



            <div className={`row ${requisicion_detalle.compra_proveedor?'mb-3':''}`}>
              <div className="col-6">
                  <h6 className="mt-3">Requisitó</h6>
                  <p className="nm"><small><b>{requisicion_detalle.idusuario_crea?.nombres}</b> {requisicion_detalle.idusuario_crea?.paterno} {requisicion_detalle.idusuario_crea?.materno}</small></p>
                  <p className="nm"><small style={{fontSize:10}}>{rol(requisicion_detalle.idusuario_crea?.rol)}</small></p>
              </div>
              <div className="col-3">
                  <h6 className="mt-3">Monto Solicitado</h6>
                  <small>$ {formatearNumero(sumarMontosTotales(requisicion_detalle?.requisiciones_listado??[]))}</small>
              </div>
              <div className="col-3">
                  <h6 className="mt-3">Monto Aprobado</h6>
                  <small>$ {formatearNumero(sumarMontosTotalesAprobados(requisicion_detalle?.requisiciones_listado??[]))}</small>
              </div>
            </div>




            <div className="row">
              <div className="col-6">
              {
                requisicion_detalle.compra_proveedor && (
                    <div>
                      <h6 className="">Proveedor</h6>
                      <small>{requisicion_detalle?.idproveedor?.proveedor} ({requisicion_detalle?.idproveedor?.razon_social})</small>
                    </div>
                  )
              }
              </div>
            </div>






{
  // JSON.stringify(requisicion_detalle.compra_proveedor)
}




                      <h6 className="mt-3">Elementos de la Requisición</h6>

                        <table className="table" style={{fontSize:10}}>
                          <thead>
                            <tr>
                              {
                                !requisicion_detalle.compra_proveedor && (<th scope="col">Factura</th>)
                              }
                              
                              <th scope="col">Tipo</th>
                              <th scope="col"></th>
                              <th scope="col">Concepto</th>
                              <th scope="col">Monto</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              requisicion_detalle?.requisiciones_listado?.map(( elemento:any, elemento_indice:number )=>
                                          <tr className={`${ [2].indexOf(elemento?.idestatus_elemento?.id) > -1 ? 'disabled-row' : '' }`} key={elemento_indice}>
                                              { 
                                                  !requisicion_detalle.compra_proveedor && (
                                                      <td className="v" style={{"width":"20px",textAlign:'center'}}>
                                                        { elemento.con_factura && <i className="fa fa-warning" />}
                                                      </td>
                                                  ) 
                                              }
                                            <td className="v" style={{"width":"100px"}}>
                                              <b>{tipoDeElementoRequisicion(elemento.tipo)}</b>
                                            </td>
                                            <td className="v" style={{"width":"50px"}}>
                                                { !elemento?.idestatus_elemento && (<>⌛</>) }
                                                { [1,3,4,5,99].indexOf(elemento?.idestatus_elemento?.id) > -1 && (<>✅</>) }
                                                { [2].indexOf(elemento?.idestatus_elemento?.id) > -1 && (<>❌</>) }
                                            </td>
                                            <td className="v" style={{'wordBreak': 'break-word'}}>
                                            <p className="nm">
                                              {elemento.concepto} {' '}

                                              {
                                                elemento.comentarios && (

                                                    <OverlayTrigger
                                                      placement="bottom"
                                                      overlay={<Tooltip id="button-tooltip-2">{elemento.comentarios.join('\n')}</Tooltip>} >
                                                        <i className="fa fa-info-circle" />
                                                    </OverlayTrigger>


                                                  )
                                              }

                                            </p>
                                              {
                                                // !elemento?.idestatus_elemento?.id && (<>{elemento?.idestatus_elemento}</>) 
                                              }
                                                { 
                                                  [2].indexOf(elemento?.idestatus_elemento?.id) > -1 && (<p className="nm"><small>{elemento.comentarios.join('\n')}</small></p>) 
                                                }

                                              {
                                                // JSON.stringify(elemento?.meta_data.detalles_deposito)
                                              }

                                              {
                                                elemento?.meta_data?.detalles_deposito && (
                                                    <>
                                                      <p className="nm"><small>Detalles</small></p>
                                                      <p className="nm"><b>{elemento?.meta_data?.detalles_deposito}</b></p>
                                                    </>
                                                  )
                                              }

                                              {
                                                [5].indexOf(elemento?.idestatus_elemento?.id) > -1 && (
                                                    <>
                                                      ✅
                                                    </>
                                                  )
                                              }

                                              {
                                                // JSON.stringify(elemento?.idegreso)
                                              }

                                              {
                                                // !requisicion_detalle.compra_proveedor && (
                                                //     <>
                                                //         <p className="nm">Fecha del Movimiento</p>
                                                //         <small className="nm">{formatearFechaEstandar(elemento?.idegreso?.fecha_movimiento??new Date())}</small>
                                                //     </>
                                                //   )
                                              }

                                              {
                                                (elemento.idegreso && elemento.idegreso?.archivo !== "") && (
                                                    <>
                                                   {
                                                    // JSON.stringify(elemento.idegreso)
                                                  }
                                                      <p className="nm"><button onClick={()=>{ window.open(`${SERVER_}contabilidad/archivo/${elemento?.idegreso?.uuid}`) }} className="btn btn-xs btn-link nm">🪙 Depósito</button></p>
                                                    </>
                                                  )
                                              }


                                               {
                                                 [5].indexOf(elemento?.idestatus_elemento?.id) > -1 && (
                                                     <>
                                                       <p className="nm"><button onClick={()=>{ window.open(`${SERVER_}comprobaciones/evidencia/archivo/${elemento?.uuid}`) }} className="btn btn-xs btn-link nm">🧾 Evidencia del Gasto</button></p>

                                                       <small>Monto Comprobado</small>
                                                       <h6 className="">$ {formatearNumero(elemento?.monto_comprobado)}</h6>

                                                       <p className="nm">
                                                         <i className="fa fa-check-square" /> Comprobado
                                                       </p>
                                                     </>
                                                   )
                                               }


                                                {
                                                  elemento.documento_compra !== null && (
                                                        <>
                                                        <p className="nm"><button className="btn btn-xs btn-link nm" onClick={()=>{ window.open(`${SERVER_}compra/evidencia/archivo/${elemento?.uuid}`) }}>⭐ Evidencia de la compra</button></p>
                                                        </>
                                                    )
                                                }


                                            </td>

{/*                                            { 
                                              ["pago","viaticos","reembolso"].indexOf(elemento.tipo) > -1 && (
                                                <>
                                                  <td className="v text-center" style={{"width":"80px"}}><small>N/A</small></td>
                                                  <td className="v text-center" style={{"width":"180px"}}><small>N/A</small></td>
                                                </>
                                              ) 
                                            }

                                            { 
                                              ["compra"].indexOf(elemento.tipo) > -1 && (
                                                <>
                                                  <td className="v text-center" style={{"width":"80px"}}>{elemento.cantidad}</td>
                                                  <td className="v text-center" style={{"width":"140px"}}>
                                                    {
                                                      // JSON.stringify(elemento)
                                                    }
                                                    {
                                                      // obtenerUnidadMedida(tipo_unidad_medida,parseInt(elemento.idunidad_medida))
                                                    }
                                                    {elemento.idunidad_medida.unidad_medida}
                                                  </td>
                                                </>
                                              ) 
                                            }
                                            */}
                                                  <td className="v" style={{"width":"80px"}}>$ {formatearNumero((elemento.monto_total*1))}</td>
                                                  <td className="v" style={{"width":"80px"}}>$ {formatearNumero((elemento.monto_aprobado*1))}</td>
                                            
                                          </tr>
                                )
                            }

                            <tr>
                              {
                                // !proveedor && (<><td></td><td></td></>)
                                !proveedor && (<><td></td></>)
                              }
                              
                              <td></td>
                              <td></td>
                              <td colSpan={2} className="v text-right"><b>Total Solicitado</b></td>
                              <td>
                                $ { requisicion_detalle?.requisiciones_listado ?  formatearNumero(sumarMontosTotales(requisicion_detalle?.requisiciones_listado)) : 0 }
                              </td>
                            </tr>
                            <tr>
                              {
                                // !proveedor && (<td></td>)
                                !proveedor && (<><td></td></>)
                              }
                              <td></td>
                              <td></td>
                              <td colSpan={2} className="v text-right"><b>Total Aprobado</b></td>
                              <td>
                                $ { requisicion_detalle?.requisiciones_listado ?  formatearNumero(sumarMontosTotalesAprobados(requisicion_detalle?.requisiciones_listado)) : 0 }
                              </td>
                            </tr>

                          </tbody>
                        </table>







                        {
                          // JSON.stringify(requisicion_detalle?.historial_movimientos)
                        }

                        <h6>Historial de Movimientos de la Requisición</h6>

      <table className="table table-sm table-bordered table-hover table-striped" style={{fontSize:10}}>
        <thead className="thead-light">
          <tr>
            <th scope="col">Fecha de Creación</th>
            <th scope="col">Estatus</th>
          </tr>
        </thead>
        <tbody>
          {requisicion_detalle?.historial_movimientos?.map((requisicion:any, index:number) => (
            <tr key={index}>
              <td>{moment(requisicion.fecha_creacion).format('DD-MM-YYYY HH:mm')} HRS</td>
              <td>{requisicion.idestatus.estatus}</td>
            </tr>
          ))}
        </tbody>
      </table>









            {
              // requisicion_detalle?.requisiciones_listado?.map((elemento_requisicion:any, elemento_requisicion_indice:any) => (
              // <div className="contenedor" key={elemento_requisicion_indice}>

              //           {
              //             !requisicion_detalle.compra_proveedor && (
              //                 <p className="">
              //                   Comprobación {' '}
              //                   <b>{elemento_requisicion.con_factura ? 'CON UNA FACTURA 🧾' : 'SIN FACTURA'}</b>
              //                 </p>
              //               )
              //           }

              //           {
              //             !requisicion_detalle.compra_proveedor && (
              //                 <>
              //                     <small>Nombre o Razón Social del Depósito</small>
              //                     <h6 className="nm">{elemento_requisicion.meta_data?.nombre_razon_social}</h6>
              //                 </>
              //               )
              //           }



              //             <small>Concepto de la requisición</small>
              //             <h6 className="nm">{elemento_requisicion?.concepto}</h6>
              //             <small>Monto Solicitado</small>
              //             <h6 className="nm">$ {formatearNumero(elemento_requisicion?.monto_total??0)}</h6>
              //             <small>Tipo de requisición</small>
              //             <h6 className="nm">{elemento_requisicion?.tipo}</h6>

              //           {
              //             elemento_requisicion.idegreso && (
              //                 <>
              //                   <small>Concepto del Depósito</small>
              //                   <h6 className="nm">{elemento_requisicion?.idegreso?.descripcion}</h6>
              //                 </>
              //               )
              //           }


              //             <small>Monto</small>
              //             <h6 className="nm">$ {formatearNumero(elemento_requisicion?.idegreso?.monto??0)}</h6>

              //           {
              //             !requisicion_detalle.compra_proveedor && (
              //                 <>
              //                     <small>Fecha del Movimiento</small>
              //                     <h6 className="nm">{formatearFechaEstandar(elemento_requisicion?.idegreso?.fecha_movimiento??new Date())}</h6>
              //                 </>
              //               )
              //           }

              //           {
              //             elemento_requisicion.idegreso && (
              //                 <>
              //                   <small>Ćomprobación de Depósito</small>
              //                   <p className="nm"><button onClick={()=>{ window.open(`${SERVER_}contabilidad/archivo/${elemento_requisicion?.idegreso?.uuid}`) }} className="btn btn-sm btn-link nm">Abrir</button></p>
              //                 </>
              //               )
              //           }

              //             {
              //               [5].indexOf(elemento_requisicion.idestatus_elemento) > -1 && (
              //                   <>
              //                     <small>Ćomprobación de Evidencia del Gasto</small>
              //                     <p className=""><button onClick={()=>{ window.open(`${SERVER_}comprobaciones/evidencia/archivo/${elemento_requisicion?.uuid}`) }} className="btn btn-sm btn-link nm">Abrir</button></p>

              //                     <small>Monto Comprobado</small>
              //                     <h6 className="">$ {formatearNumero(elemento_requisicion?.monto_comprobado)}</h6>

              //                     <p className="nm">
              //                       <i className="fa fa-check-square" /> Comprobado
              //                     </p>
              //                   </>
              //                 )
              //             }

              //   </div>
              //   ))
          }


        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalRequisicionDetalles;